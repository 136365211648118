<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="_positions"
  [cdkConnectedOverlayHasBackdrop]="hasBackdrop || closeOnBackdropClick"
  [cdkConnectedOverlayBackdropClass]="_backdropClass"
  [cdkConnectedOverlayPanelClass]="_popoverClasses"
  [cdkConnectedOverlayDisableClose]="true"
  [cdkConnectedOverlayPush]="keepOnScreen"
  (backdropClick)="onBackdropClick()"
>
  <div
    class="glxy-popover-container"
    [class.padding-large]="padding === 'large'"
    [class.padding-small]="padding === 'small'"
    [class.high-contrast]="highContrast"
    [class.has-arrow]="hasArrow && (isFullscreen$ | async) === false"
    [class.glxy-popover--fullscreen]="mobileFullScreen && (isFullscreen$ | async)"
    [style.max-width]="_cooercedMaxWidth"
    [style.max-height]="_cooercedMaxHeight"
  >
    <div class="glxy-popover-inner-container">
      <ng-content select="glxy-popover-title, [glxyPopoverTitle]"></ng-content>
      <div class="glxy-popover-main-content">
        <ng-content></ng-content>
      </div>
      <ng-content select="glxy-popover-actions, [glxyPopoverActions]"></ng-content>
    </div>

    @if (hasArrow && (isFullscreen$ | async) === false) {
      <div class="glxy-popover-arrow"></div>
    }

    @if (mobileFullScreen && showMobileClose && (isFullscreen$ | async)) {
      <div class="glxy-popover-close">
        <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
      </div>
    }
  </div>
</ng-template>
