import { Component } from '@angular/core';

@Component({
  template: `
    <div class="value" *ngIf="value">
      <ng-container *ngIf="link">
        <a [routerLink]="link">{{ value }}</a>
      </ng-container>
      <ng-container *ngIf="!link && action">
        <a (click)="action()">{{ value }}</a>
      </ng-container>
      <ng-container *ngIf="!link && !action">
        {{ value }}
      </ng-container>
    </div>
    <glxy-blank-value *ngIf="!value"></glxy-blank-value>
  `,
})
export class TextRendererComponent {
  value: string | undefined;
  link: string | undefined;
  action: (() => void) | undefined;
}
