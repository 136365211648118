import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

function escapeHtml(code: string): any {
  return code
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderer.code = function (code, _language): string {
    return `<pre><code>${escapeHtml(code)}</code></pre>`;
  };

  return {
    renderer: renderer,
  };
}
