<mat-toolbar class="header white-bg">
  <mat-toolbar-row>
    <mat-icon
      *ngIf="secondaryNavigation"
      class="hide-gt-sm"
      (click)="toggle()"
      style="margin-right: 16px; cursor: pointer"
    >
      menu
    </mat-icon>
    <a [routerLink]="['']" class="application-title">
      <img
        style="margin-right: 16px"
        src="https://vstatic-prod.apigateway.co/developer-center-client.9223372035138901697.prod/assets/images/vendasta-logo.svg"
        alt="Vendasta"
      />
      <span style="font-weight: 300">Developer Center</span>
    </a>
    <div style="flex: 1 1 auto"></div>
    <button mat-flat-button style="background: #3c9a63; color: white" (click)="openDialog()">
      <b>Generate Token</b>
    </button>
    <mat-nav-list class="hide-lt-md" class="nav-list">
      <a mat-nav-list-item routerLink="/platform" routerLinkActive="active">Partner Platform</a>
      <a mat-nav-list-item routerLink="/products/products" routerLinkActive="active">SMB Products</a>
      <a mat-nav-list-item routerLink="/vendor" routerLinkActive="active">Vendors</a>
    </mat-nav-list>
  </mat-toolbar-row>
  <mat-toolbar-row class="mobile-menu" class="hide-gt-sm">
    <mat-nav-list class="nav-list">
      <a mat-nav-list-item routerLink="/platform" routerLinkActive="active">Platform</a>
      <a mat-nav-list-item routerLink="/vendor" routerLinkActive="active">Vendors</a>
      <a mat-nav-list-item routerLink="/api" routerLinkActive="active">Legacy</a>
    </mat-nav-list>
  </mat-toolbar-row>
</mat-toolbar>
