import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stoplight',
  templateUrl: './stoplight.component.html',
  styleUrls: ['./stoplight.component.scss'],
})
export class StoplightComponent implements OnInit {
  public basePath: string;
  public projectId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.projectId = data.projectId;
      this.basePath = data.basePath;
    });
  }
}
