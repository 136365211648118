import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewChecked,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, skipWhile } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { GalaxyTableSearchService } from '../../services/table-search.service';
import { GalaxyTableSelectionService } from '../../services/table-selection.service';

const SEARCH_TERM_DELAY = 300; // ms

@Component({
  selector: 'glxy-table-content-header',
  templateUrl: './table-content-header.component.html',
  styleUrls: ['./table-content-header.component.scss'],
  providers: [],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          height: '0px',
          opacity: 0,
        }),
      ),
      transition('open <=> closed', [animate('0.2s')]),
    ]),
  ],
})
export class TableContentHeaderComponent implements OnInit, OnChanges, AfterViewChecked {
  @HostBinding('class') class = 'glxy-table-content-header';

  @Input() showColumnArrange?: boolean = true;
  @Input() columnArrangeType?: 'simple' | 'advanced' = 'simple';
  @Input() showFiltersButton?: boolean = true;
  @Input() showFilters?: boolean = true;
  @Input() showFiltersOpen?: boolean = true;
  @Input() showFiltersApplied?: boolean = false;
  @Input() showSearch?: boolean = true;
  @Input() showSort?: boolean = true;
  @Input() showExport?: boolean = false;
  @Input() showActions?: boolean = true;
  @Input() saveSearchToQueryParams?: boolean = false;
  @Input() showSelectedAllCount?: boolean = false;
  @Input() showDisplayOption = false;
  @Input() selectedDisplayOption: 'grid' | 'list' = 'list';

  // overrides the search value with NgOnChanges
  @Input() emitSearchChange?: string;

  @Output() export = new EventEmitter<void>();
  @Output() displayOptionChanged = new EventEmitter<'grid' | 'list'>();

  searchControl: UntypedFormControl = new UntypedFormControl();
  numSelected$?: Observable<number>;

  private initialSearchTerm = '';
  private initialSearchTermSet = false;

  constructor(
    private searchService: GalaxyTableSearchService,
    private selectionService: GalaxyTableSelectionService,
    private destroyRef: DestroyRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngAfterViewChecked(): void {
    if (this.initialSearchTerm && !this.initialSearchTermSet) {
      this.searchControl.setValue(this.initialSearchTerm);
      this.searchControl.updateValueAndValidity();
      this.initialSearchTermSet = true;
    }
  }

  ngOnInit(): void {
    if (this.saveSearchToQueryParams) {
      const searchParam = this.activatedRoute.snapshot.queryParamMap.get('search');
      if (searchParam) {
        this.initialSearchTerm = decodeURI(searchParam);
      }
    }

    // When the user enters a search term, update and emit.
    this.searchControl.valueChanges
      .pipe(
        skipWhile((term) => term !== this.initialSearchTerm),
        debounceTime(SEARCH_TERM_DELAY),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (text: string) => {
          this.setSearchTerm(text);
        },
      });
    // When the search term is programmatically updated, update without emitting.
    this.searchService.searchTerm$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (searchTerm) => {
        this.searchControl.setValue(searchTerm, { emitEvent: false });
        this.searchControl.updateValueAndValidity();
      },
    });

    this.numSelected$ = this.selectionService.selection$.pipe(map((selection) => selection.length));
    this.displayOptionChanged.emit(this.selectedDisplayOption);
  }

  ngOnChanges() {
    if (this.emitSearchChange) {
      this.searchControl.setValue(this.emitSearchChange);
      this.searchControl.updateValueAndValidity();
    }
  }

  setSearchTerm(searchTerm: string): void {
    if (this.saveSearchToQueryParams) {
      let searchParam: string | null = null;
      if (searchTerm) {
        searchParam = encodeURI(searchTerm);
      }
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { search: searchParam },
        queryParamsHandling: 'merge',
      });
    }
    this.searchService.updateTerm(searchTerm);
  }

  toggleFilterBar(): void {
    this.showFiltersOpen = !this.showFiltersOpen;
  }

  onChangeDisplayOption(value: 'grid' | 'list'): void {
    this.selectedDisplayOption = value;
    this.displayOptionChanged.emit(value);
  }
}
