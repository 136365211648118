import { Injectable } from '@angular/core';
import { EnvironmentService, Environment } from '@galaxy/core';

@Injectable()
export class HostService {
  private _host: string;
  private _httpsHost: string;

  constructor(private environmentService: EnvironmentService) {}

  host(): string {
    if (this._host) {
      return this._host;
    }

    switch (this.environmentService.getEnvironment()) {
      case Environment.LOCAL:
        this._host = '';
        break;
      case Environment.TEST:
        this._host = '';
        break;
      case Environment.DEMO:
        this._host = 'developer-center-api-demo.apigateway.co';
        break;
      case Environment.PROD:
        this._host = 'developer-center-api-prod.apigateway.co';
        break;
    }
    return this._host;
  }

  httpsHost(): string {
    if (this._httpsHost) {
      return this._httpsHost;
    }

    switch (this.environmentService.getEnvironment()) {
      case Environment.LOCAL:
        this._httpsHost = '';
        break;
      case Environment.TEST:
        this._httpsHost = '';
        break;
      case Environment.DEMO:
        this._httpsHost = 'developer-center-demo.apigateway.co';
        break;
      case Environment.PROD:
        this._httpsHost = 'developer-center-prod.apigateway.co';
        break;
    }
    return this._httpsHost;
  }

  hostWithScheme(): string {
    const scheme = this.environmentService.getEnvironment() === Environment.LOCAL ? 'http://' : 'https://';
    return scheme + this.host();
  }

  httpsHostWithScheme(): string {
    const scheme = this.environmentService.getEnvironment() === Environment.LOCAL ? 'http://' : 'https://';
    return scheme + this.httpsHost();
  }
}
