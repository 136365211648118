import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-toc',
  templateUrl: './toc.component.html',
  styleUrls: ['./toc.component.scss'],
})
export class TocComponent {
  @Input() content: any;
  private headers$$: BehaviorSubject<HTMLElement[]> = new BehaviorSubject<HTMLElement[]>([]);

  get headers() {
    return this.headers$$.pipe(distinctUntilChanged());
  }

  public onContentChange() {
    const elm = this.content._container._element.nativeElement;
    const headers = elm.querySelectorAll('h1, h2, h3');
    headers.forEach((header: { id: any; innerText: string }) => {
      if (!header.id) {
        header.id = header.innerText.toLowerCase().split(' ').join('-');
      }
    });
    this.headers$$.next(headers);
  }

  // Normally, you would just use href="#id" to change just the anchor/fragment.
  // But, for some reason related to the base href of this project, it changes the anchor relative to the URL.
  buildURL(id: string) {
    return `${window.location.pathname}#${id}`;
  }
}
