import { Component } from '@angular/core';

@Component({
  selector: 'glxy-float-renderer',
  template: `
    <div class="value" *ngIf="value">
      {{ value | number }}
    </div>
    <glxy-blank-value *ngIf="!value"></glxy-blank-value>
  `,
})
export class FloatRendererComponent {
  value: number | undefined;
}
