import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatColumnDef, MatHeaderRowDef, MatRowDef, MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { GalaxyDataSource } from '../../datasource/datasource';
import { GalaxyColumnsSortService } from '../../services/column-sort.service';
import { GalaxyTableSearchService } from '../../services/table-search.service';
import { GalaxyTableSelectionService } from '../../services/table-selection.service';
import { GalaxyColumnDef } from '../../table.interface';
import { CellData, Row, RowData } from '../model-driven-cell/interface';
import { TableContentHeaderComponent } from '../../../../table';
import { GridViewComponent } from '../grid-view/grid-view.component';
import { GalaxyGridCardDirective } from '../grid-view/grid-card.directive';
import { GalaxyGridMinCardWidthDirective, GRID_MIN_CARD_WIDTH } from '../grid-view/grid-min-width.directive';

// If nothing is provided, the frontend service handles everything
@Component({
  selector: 'glxy-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss'],
  providers: [GalaxyTableSelectionService, GalaxyTableSearchService, GalaxyColumnsSortService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableContainerComponent<RowType> implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @HostBinding('class') class = 'glxy-table-container';

  @HostBinding('class.has-border')
  @Input()
  border = true;

  @HostBinding('class.glxy-table-full-width')
  @Input()
  fullWidth = false;

  /* Used to identify the table on a given page, when applying user settings */
  @Input() id = '';

  @Input({ required: true }) dataSource!: GalaxyDataSource<RowType, unknown, unknown>;

  @Input() columns: GalaxyColumnDef[] = [];
  @Input() groupConfigs: GalaxyColumnDef[] = [];

  @Input() showSelection = false;
  @Input() singleSelection = false;

  @Input() pageSizeOptions?: number[];
  @Input() pageSize?: number;
  @Input() showFooter = true;

  @Input() currentDisplayOption: 'grid' | 'list' = 'list';

  @Output() selectionChanged: EventEmitter<Row[]> = new EventEmitter();
  @Output() columnsChanged: EventEmitter<GalaxyColumnDef[]> = new EventEmitter();

  @ContentChild(MatTable) table?: MatTable<any>;
  @ContentChild(MatHeaderRowDef) headerRowDef?: MatHeaderRowDef;
  @ContentChild(MatRowDef) rowDef?: MatRowDef<any>;
  @ContentChildren(MatColumnDef, { descendants: true }) columnDefs?: QueryList<MatColumnDef>;
  private subscriptions: Subscription[] = [];

  @ViewChildren(MatColumnDef)
  private allMatColumnDefs?: QueryList<MatColumnDef>;

  @ViewChild('scrollContainer', { static: false }) scrollContainer?: ElementRef;

  @ContentChild(forwardRef(() => TableContentHeaderComponent)) tableContentHeader?: TableContentHeaderComponent;

  @ViewChild(GridViewComponent)
  set gridView(grid: GridViewComponent<RowType>) {
    if (grid) {
      grid.dataSource = this.dataSource;
    }
  }

  @ContentChild(GalaxyGridCardDirective)
  set setCardDirective(cardDirective: GalaxyGridCardDirective) {
    this.cardDirective = cardDirective;
  }
  cardDirective?: GalaxyGridCardDirective;

  @ContentChild(GalaxyGridMinCardWidthDirective)
  set setMinCardWidthDirective(minCardWidthDirective: GalaxyGridMinCardWidthDirective) {
    if (minCardWidthDirective) {
      this.gridCardMinWidth = minCardWidthDirective.glxyGridMinCardWidth;
    }
  }
  gridCardMinWidth = GRID_MIN_CARD_WIDTH;

  constructor(
    private columnSortService: GalaxyColumnsSortService,
    private selectionService: GalaxyTableSelectionService,
    private searchService: GalaxyTableSearchService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showSelection']) {
      this.updateServicesAndDatasource();
    }
  }

  ngOnInit(): void {
    if (this.showSelection && this.singleSelection) {
      this.selectionService.setSelectionMode('single');
    }
    const searchSub = this.searchService.searchTerm$.subscribe({
      next: (searchTerm) => {
        this.dataSource?.setSearchTerm(searchTerm);
      },
    });

    const selectSub = this.selectionService.selection$.subscribe({
      next: (rows: Row[]) => this.selectionChanged.emit(rows),
    });

    const columnSub = this.columnSortService.columns$$
      .pipe(
        filter((columns: GalaxyColumnDef[]) => columns.length > 0),
        distinctUntilChanged(),
      )
      .subscribe({
        next: (columns: GalaxyColumnDef[]) => this.columnsChanged.emit(columns),
      });

    const clearSelectionSub = this.dataSource.shouldClearSelection$
      .pipe(
        filter((shouldClear) => !!shouldClear),
        tap(() => {
          this.selectionService.clear();
        }),
      )
      .subscribe();

    const selectAllSub = this.dataSource.selectAll$
      .pipe(
        filter((selectAll) => !!selectAll),
        tap(() => {
          this.selectionService.entries.forEach((entry) => {
            if (entry.select.row && !this.selectionService.isSelected(entry.select.row)) {
              this.selectionService.multiSelection.select(entry.select.row);
            }
          });
        }),
      )
      .subscribe();

    this.subscriptions.push(searchSub, selectSub, columnSub, clearSelectionSub, selectAllSub);
  }

  ngAfterViewInit(): void {
    if (this.tableContentHeader) {
      this.tableContentHeader.displayOptionChanged.subscribe((option) => {
        this.currentDisplayOption = option;
      });

      this.currentDisplayOption = this.tableContentHeader.selectedDisplayOption;
      this.changeDetectorRef.detectChanges();
    }

    this.scrollContainer?.nativeElement.addEventListener(
      'scroll',
      () => {
        const el = this.scrollContainer?.nativeElement;
        const scrollLeft = el.scrollLeft;

        // apply a class to control conditional styles if sticky columns are overlapping or not
        // left column has styles unless scrolled to the start
        if (scrollLeft >= 2) {
          el.classList.add('show-sticky-shadow');
        } else {
          el.classList.remove('show-sticky-shadow');
        }
        // right column has styles unless scrolled to end
        if (Math.abs(scrollLeft) === el.scrollWidth - el.clientWidth) {
          el.classList.remove('show-sticky-shadow-end');
        } else {
          el.classList.add('show-sticky-shadow-end');
        }
      },
      { capture: false, passive: true },
    );

    const viewChildrenColumnsSub = this.allMatColumnDefs?.changes.subscribe((defs) => {
      this.updateMatColumns(defs);
    });
    if (this.allMatColumnDefs) this.updateMatColumns(this.allMatColumnDefs);

    const colSortSub = this.columnSortService.visibleColumns$.subscribe({
      next: (displayedColumns: string[]) => {
        if (this.headerRowDef) this.headerRowDef.columns = displayedColumns;
        if (this.rowDef) this.rowDef.columns = displayedColumns;
      },
    });

    if (viewChildrenColumnsSub) {
      this.subscriptions.push(viewChildrenColumnsSub, colSortSub);
    }
  }

  private updateMatColumns(defs: QueryList<MatColumnDef>): void {
    const columnDefsFiltered = defs.filter((def) => !this.columnDefs?.find((c) => c.name === def.name));

    // need to delete and re-add the columns,
    // since we can't easily inspect the columns inside a MatTable
    // to know which ones are model-driven or from the template using our table
    columnDefsFiltered.forEach((def) => {
      this.table?.removeColumnDef(def);
    });
    columnDefsFiltered.forEach((def: MatColumnDef) => {
      this.table?.addColumnDef(def);
    });

    this.updateServicesAndDatasource();
  }

  private updateServicesAndDatasource(): void {
    const externalColumnDefs = this.columnDefs;
    const modelDrivenColumnDefs =
      this.allMatColumnDefs?.filter((def) => !externalColumnDefs?.find((external) => external.name === def.name)) || [];
    const columnsDefs = [...(externalColumnDefs || []), ...modelDrivenColumnDefs];

    if (this.table) {
      this.table.dataSource = this.dataSource;
      const cols = [...(this.columns || [])];
      if (cols[0]?.id !== 'select') {
        cols.unshift({
          id: 'select',
          pinned: true,
          sticky: true,
          hidden: !this.showSelection,
        });
      }
      this.columnSortService.setColumns(cols);
      this.columnSortService.setColumnDefs(columnsDefs);
      this.columnSortService.setGroups(this.groupConfigs);
    }
  }

  // Searching between HTML and TS doesn't work well in IDE.
  // If it's all TS code, it's easier to find data-type usages.
  getCellData(row: RowData, columnId: string): CellData {
    return row[columnId];
  }

  updateSorting(sortOption: Sort): void {
    if (sortOption.direction === '') {
      this.dataSource.setSorting([]);
      return;
    }
    this.dataSource.setSorting([{ active: sortOption.active, direction: sortOption.direction }]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());

    this.scrollContainer?.nativeElement?.removeEventListener('scroll', { capture: false });
  }
}
