import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export const INDETERMINATE_MAX = Infinity;

export class GalaxyPaginatorText extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
  }

  // Label that goes beside the page selector
  itemsPerPageLabel = this.translate.instant('GALAXY.PAGINATION.ITEMS_PER_PAGE');
  // Lable that goes in the tooltip for the next page button
  nextPageLabel = this.translate.instant('GALAXY.PAGINATION.NEXT_PAGE');
  // Lable that goes in the tooltip for the previous page button
  previousPageLabel = this.translate.instant('GALAXY.PAGINATION.PREVIOUS_PAGE');

  /**
   * Contruct the label that displays how many items exist in the series, when using an endpoint that doesn't
   * provide a total items prop.
   * @param page - The current page number
   * @param pageSize - The size of the page
   * @param length - How many items exist in the entire data set
   * @returns The translated string for display number of items on the page, out of total
   * "1 - 10 of many", "11-15 of 15"
   */
  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 of ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    // "Of many" rather than of "length"
    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('GALAXY.PAGINATION.OF')} ${
      length === INDETERMINATE_MAX ? this.translate.instant('GALAXY.PAGINATION.INDETERMINATE_AMOUNT') : length
    }`;
  };
}
