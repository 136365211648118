import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject } from 'rxjs';
import { GalaxyTableSelectionService } from '../../services/table-selection.service';
import { Row } from '../model-driven-cell/interface';

// First iteration only allows for the current page to be selected
@Component({
  selector: 'glxy-table-selection',
  templateUrl: './selection.component.html',
})
export class GalaxySelectionComponent implements OnInit, OnChanges, OnDestroy {
  // If no row is present, we can assume that this is the mother of all
  // checkboxes. It controls them all
  @Input() row?: Row;
  @Input() preSelectedRows: Row[] = [];
  @Input() preSelectedLockedRows: Row[] = [];

  disabledRow = false;
  selected$$ = new BehaviorSubject<boolean | null>(null);

  constructor(public selectionService: GalaxyTableSelectionService) {}

  // For now, we register when created (aka only allow for one page at a time)
  ngOnInit(): void {
    this.selectionService.registerRow(this);
  }

  // If the row is preselected, we disable the checkbox
  ngOnChanges(changes: SimpleChanges) {
    if (this.preSelectedLockedRows && this.row) {
      if (changes?.preSelectedLockedRows?.currentValue || changes?.row?.currentValue) {
        const matchingRow = this.preSelectedLockedRows?.some((item) => item.id === this.row?.id);
        this.disabledRow = matchingRow;
      }
    }
    if (this.preSelectedRows && this.row) {
      if (changes?.preSelectedRows?.currentValue || changes?.row?.currentValue) {
        const matchingRow = this.preSelectedRows?.some((item) => item.id === this.row?.id);
        //Only change the value if it's the matching row
        if (matchingRow) {
          this.selectionService.setSelected(this.row);
        }
      }
    }
  }

  // For now, we unregister when destroyed (aka only allow for one page at a time)
  ngOnDestroy(): void {
    this.selectionService.unregisterRow(this);
  }

  onSelectChange(change: MatCheckboxChange): void {
    this.selected$$.next(change.checked);
  }
}
