import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Environment, SessionService } from '@galaxy/core';
import { IAMService, PartnerPersona, PersonaType } from '@vendasta/iam';
import { TokenData, parseTokenInsecure } from '@vendasta/iamv2';
import { PostHog, Properties, Property } from 'posthog-js';
import { of } from 'rxjs';
import { catchError, filter, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Config } from './config';

/**
 * Common properties that can be used for tracking events
 */
export enum CommonProperties {
  TOGGLE_STATE = 'toggleState',
  ECOMMERCE = 'Ecommerce',
}

/**
 * Common actions that can be used for tracking events
 */
export enum CommonActions {
  TOGGLE_STATE_ON = 'toggleOn',
  TOGGLE_STATE_OFF = 'toggleOff',
  CLICK = 'click',
}

/**
 * Posthog's UUID format. If an ID doesn't match this format, it's a vendasta ID.
 * Note that we can't simply use U-* as in some centers emails or business IDs are
 * the only available identifiers (e.g. think an SMB logging in).
 */
const POSTHOG_ANONYMOUS_ID_REGEX = '.+?-.+?-.+?-.+?-.+?';

/**
 * The group type if grouping by Partner ID.
 * @private
 */
const POSTHOG_GROUP_PARTNER = 'partner';

@Injectable({ providedIn: 'root' })
export class ProductAnalyticsService {
  private initialized = false;
  private previousUrl?: string;
  private instance?: PostHog;
  private propertiesQueue: Properties[] = [];

  /**
   *
   * @param router Used to monitor page changes.
   * @param sessionService Used to interact with the browser session and stored information e.g. User ID
   * @param iamService Used to interact with the IAM service to get the current user's information
   */
  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService,
    private readonly iamService: IAMService,
  ) {}

  /**
   * Initializes the analytics service.
   * @param config
   */
  initialize(config: Config): void {
    if (this.initialized) {
      return;
    }
    // Check if snowplow should be configured and we have the projects UUID
    if (!!config && !!config.projectUUID) {
      const persona$ = this.sessionService.getSessionId().pipe(
        switchMap((session) => {
          if (session) {
            return this.iamService.getSubjectBySession(session, PersonaType.partner).pipe(catchError(() => of(null)));
          }
          return of(null);
        }),
      );

      persona$.pipe(take(1), withLatestFrom(this.sessionService.getSessionId())).subscribe(([persona, session]) => {
        let isSuperAdmin = false;
        if (persona) {
          const partnerSubject = persona as PartnerPersona;
          if (partnerSubject.isSuperAdmin) {
            isSuperAdmin = true;
          }
        }

        let token: TokenData | null;
        if (session) {
          // Yes, a null session is stored as the string 'null' in some places.
          if (!!session && session !== 'null') {
            token = parseTokenInsecure(session);
          }
        }

        // if post hog analytics has been enabled then also wire it up
        if (config.postHogID) {
          let environmentString = 'demo';
          if (config.environment === Environment.PROD) {
            environmentString = 'production';
          }

          // Do the required PostHog configuration
          const posthog = new PostHog();
          this.instance = <PostHog>posthog.init(
            config.postHogID,
            {
              api_host: 'https://pa.apigateway.co',
              opt_in_site_apps: true,
              session_recording: {
                blockSelector: '[data-guru-chunk-id="TopFrame"]',
              },

              // Start with session recording disabled.
              // This prevents incompatible browser extensions from fighting with PostHog.
              loaded: (ph) => {
                // If a Vendasta ID has been provided, it means the user has logged in OR a new user has logged in.
                if (token?.userId) {
                  // If the user was previously unknown, track them under their Vendasta ID.
                  if (ph.get_distinct_id().match(POSTHOG_ANONYMOUS_ID_REGEX)) {
                    ph.identify(token.userId);
                  }
                  // If the user has an ID but it's changed, a logout has occurred.
                  else if (ph.get_distinct_id() !== token.userId) {
                    ph.reset();
                    ph.identify(token.userId);
                  }
                }

                if (config.partner?.pid) {
                  ph.group(POSTHOG_GROUP_PARTNER, config.partner?.pid, {
                    name: config.partner?.name || '',
                  });
                }

                ph.register({
                  environment: environmentString,
                  projectName: config.projectName,
                  projectID: config.projectUUID,
                  businessId: config.businessID,
                  impersonateeUserId: token?.impersonateeUserId || '',
                  isSuperAdmin: isSuperAdmin,
                  partnerId: config.partner?.pid,
                });

                // Register any properties that were queued up before initialization.
                this.propertiesQueue.forEach((properties) => ph.register(properties));
                this.propertiesQueue = [];
              },
            },
            config.projectName,
          );

          // Optionally, listen for changes in the PID.
          if (config.partner?.pidChanges$) {
            config.partner.pidChanges$.subscribe((pid) => this.switchPartnerId(pid));
          }
        }

        this.initialized = true;
      });

      // Track any page views via the router
      this.router.events
        .pipe(filter((event: Event | RouterEvent): event is NavigationEnd => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          // Sometimes NavigationEnd will be fired twice, this limits to only tracking on new events
          if (event.url !== this.previousUrl) {
            this.previousUrl = event.urlAfterRedirects;
            if (config.postHogID) {
              this.instance?.capture('$pageview');
            }
          }
        });
    }
  }

  private capture = (
    eventName: string,
    category: string,
    action: string,
    value?: number,
    rawProperties?: Properties,
  ) => {
    // If PostHog not initialized, don't track the event.
    if (!this.initialized) {
      // Useful for local debugging:
      // console.log(`Tracking: ${label}: ${JSON.stringify(properties)}`);
      return;
    }

    // Ensure we have an object to work with to make the rest of this method simpler.
    const properties = rawProperties || {};

    // set the category, action and value onto the tracked event properties
    properties['event_category'] = category;
    properties['event_action'] = action;
    properties['event_value'] = value;

    // Each property passed through the tracker is a custom property. This makes it challenging
    // to bundle the full set of custom event data into a well known schema within the event stream
    // event_metadata becomes the well known property that we place the properties onto
    // https://github.com/vendasta/heimdall/blob/master/internal/event/model/event.go#L40
    const event_metadata: Record<string, Property> = {};
    Object.keys(properties).forEach((key) => {
      if (key !== 'token') {
        event_metadata[key] = properties[key];
      }
    });

    // Only place the event_metadata onto the capture call if keys exist
    if (Object.keys(event_metadata).length > 1) {
      properties.event_metadata = event_metadata;
    }

    this.instance?.capture(eventName, properties);
  };

  /**
   * Tracks a custom event.
   * For example to capture an event named 'Registered' analyticsService.trackEvent('Registered', {'Gender': 'Male', 'Age': 21});
   * @param eventName - The name of the event.
   * @param category - Typically the object that was interacted with (e.g. 'Video')
   * @param action - The type of interaction (e.g. 'play', 'click')
   * @param value - A numeric value associated with the event (e.g. 42)
   * @param properties - A set of properties to include with the event you're sending. These describe the user who did the event or details about the event itself.
   */
  trackEvent(eventName: string, category: string, action: string, value?: number, properties?: Properties): void {
    this.capture(eventName, category, action, value, properties);
  }

  /**
   * Tracks the given properties on all events going forward (until changed by a subsequent call to this method).
   * Example: associate subscription tier with all future events: analyticsService.trackProperties({ subscriptionTier: 'basic' });
   * @param rawProperties - The properties to attach to future events (until changed)
   */
  trackProperties(rawProperties: Properties): void {
    const properties = rawProperties || {};

    // If PostHog isn't initialized, don't track the property.
    if (!this.initialized) {
      this.propertiesQueue.push(properties);
      return;
    }

    // Register the properties.
    this.instance?.register(properties);
  }

  /**
   * Tracks the toggling on or off of some control, e.g. toggle switch, checkbox, etc.
   * @param eventName A label for the item being toggled
   * @param toggleState The state of the toggled control, true being "ON" and false being "OFF"
   * @param properties Optional properties in addition to the toggle state. Any fields matching a key in `CommonProperties` may be overwritten.
   */
  trackToggle(eventName: string, toggleState: boolean, properties?: Properties): void {
    const props = properties || {};
    props[CommonProperties.TOGGLE_STATE] = toggleState;

    let action = CommonActions.TOGGLE_STATE_OFF;
    let value = 0;
    if (toggleState) {
      action = CommonActions.TOGGLE_STATE_ON;
      value = 1;
    }

    this.capture(eventName, CommonProperties.TOGGLE_STATE, action, value, props);
  }

  /**
   * Access the an identifier for the current visitor
   * If the posthog is not initialized or an error occurs, it returns a empty string
   * @returns posthog's distinct_id
   */
  getVisitorId(): string {
    if (!this.initialized) {
      return '';
    }

    try {
      return this.instance?.get_distinct_id() || '';
    } catch (e) {
      return '';
    }
  }

  /**
   * Get the feature flag for the given feature flag name.
   * @param name
   */
  getFeatureFlag(name: string): boolean | string | undefined {
    return this.instance?.getFeatureFlag(name);
  }

  /**
   * Override a feature flag. This will persist until you call override again with the argument false:
   * @param flags
   */
  overrideFeatureFlag(flags: boolean | string[] | Record<string, string | boolean>): void {
    this.instance?.featureFlags.override(flags);
  }

  /**
   * Changes the partner ID for all events going forward.
   * Ignores empty PIDs, and uppercases all PIDs provided.
   * @param partnerId
   * @note Since there may be several ways to switch partner ID and partner ID
   * is core to virtually everything in the platform, we wanted a dedicated
   * method for this.
   */
  switchPartnerId(partnerId: string) {
    // Note: PostHog groups are event-based, so once you do this events
    // going forward will have the PID as both the registered PID
    // and the registered group.
    // https://posthog.com/docs/user-guides/group-analytics
    if (!partnerId) {
      return;
    }
    const sanitizedPid = partnerId.toUpperCase();
    this.instance?.group(POSTHOG_GROUP_PARTNER, sanitizedPid);
    this.trackProperties({ partnerId: sanitizedPid });
  }
}
