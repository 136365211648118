import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Environment, EnvironmentService } from '@galaxy/core';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';
import { IAccessToken, IFormData } from './shared/access-token/access-token.component';

export function getDecodedAccessToken(token: string): any {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
}
interface AuthConfigs {
  [env: number]: {
    issuer: string;
    redirectUri: string;
    clientId: string;
  };
}

export const baseAuthConfig = {
  // This is required because IAM and SSO serve different parts of the oauth2 workflow
  // IAM issues the tokens but sso handles auth and exchange of codes for tokens
  strictDiscoveryDocumentValidation: false,
  // silentRefreshRedirectUri:'http://localhost:11001/silent',
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  useSilentRefresh: true,
  // response type must be 'code' for PKCE flow
  responseType: 'code',
};

export const authConfigForEnv: AuthConfigs = {
  [Environment.LOCAL]: {
    issuer: 'https://iam-prod.apigateway.co',
    redirectUri: 'https://5adc-2405-201-e027-e16c-2c5c-d418-73b1-70f6.ngrok.io',
    clientId: '10619561-5fda-4b4a-b98a-e6d297f583c6',
  },
  [Environment.DEMO]: {
    issuer: 'https://iam-demo.apigateway.co',
    redirectUri: 'https://developer-center-demo.vendasta-internal.com/developers/',
    clientId: '77965628-82dc-46e7-8fb9-9685a2a604d0',
  },
  [Environment.PROD]: {
    issuer: 'https://iam-prod.apigateway.co',
    redirectUri: 'https://developers.vendasta.com',
    clientId: '10619561-5fda-4b4a-b98a-e6d297f583c6',
  },
};

export function validateAndSetBearerToken(token: string, env: string) {
  if (env === 'prod') {
    env = 'Prod';
  } else {
    env = 'Demo';
  }
  const existingToken = window.localStorage.getItem('TryIt_securitySchemeValues');
  const existingTokenObj = JSON.parse(existingToken);
  if (existingTokenObj) {
    existingTokenObj['OAuth2' + env] = 'Bearer ' + token;
    localStorage.setItem('TryIt_securitySchemeValues', JSON.stringify(existingTokenObj));
  } else {
    const key_val = 'OAuth2' + env;
    const element = { [key_val]: 'Bearer ' + token };
    localStorage.setItem('TryIt_securitySchemeValues', JSON.stringify(element));
  }
}

export function getAccessTokens(): IAccessToken[] {
  try {
    const storedTokens = window.localStorage.getItem('vendasta_tokens');
    return storedTokens ? JSON.parse(storedTokens) : [];
  } catch (error) {
    return [];
  }
}

export function validateToken(obj: IFormData): IAccessToken {
  const tokens = getAccessTokens();
  const token = tokens.find((token) => {
    return (
      token.environment === obj.environment &&
      token.partner === obj.partner &&
      token.scopes === obj.scope.replace(/,/g, ' ')
    );
  });
  if (token) {
    return token;
  }
  return {} as IAccessToken;
}

@Injectable({ providedIn: 'root' })
export class AccessTokenService {
  constructor(
    private oauthService: OAuthService,
    private environmentService: EnvironmentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
  ) {
    this.oauthService.setStorage(sessionStorage);
  }

  setupConfig(obj: IFormData): AuthConfig {
    if (obj['environment'] && obj['environment'] === 'prod') {
      const config = {
        ...authConfigForEnv[Environment.PROD],
        ...baseAuthConfig,
        ...{
          scope: obj['scope'],
          customQueryParams: {
            partner_id: obj['partner'],
          },
        },
      };
      this.oauthService.configure(config);
      return config;
    } else {
      const config = {
        ...authConfigForEnv[Environment.DEMO],
        ...baseAuthConfig,
        ...{
          scope: obj['scope'],
          customQueryParams: {
            partner_id: obj['partner'],
          },
        },
      };
      this.oauthService.configure(config);
      return config;
    }
  }
  //This is the oauth refresh logic. It does a silent refresh of your token

  public loginAndSetupTokenOnDevCenter(obj: IFormData): Promise<IAccessToken> {
    this.setupConfig(obj);

    const wind = window.open('about:blank', 'authPopup2', 'width=800,height=800');

    return this.oauthService.loadDiscoveryDocument().then(() => {
      return this.oauthService
        .initLoginFlowInPopup({ height: 1200, width: 1200, windowRef: wind })
        .then(() => {
          const token: IAccessToken = {
            environment: obj['environment'],
            partner: obj['partner'],
            scopes: obj.scope.replace('/,/g', ' '),
            accessToken: this.oauthService.getAccessToken(),
          };
          this.oauthService.tryLoginCodeFlow();
          validateAndSetBearerToken(token.accessToken, token.environment);
          return token;
        })
        .catch((err) => {
          if (err.params) {
            wind.alert(
              'Error while logging in: ' +
                err.params.error +
                ' \n Error Description : ' +
                err.params.error_description.replace(/\+/g, ' '),
            );
          } else {
            wind.alert('Error while logging in: ' + JSON.stringify(err));
          }
          //return an empty token
          return {} as IAccessToken;
        });
    });
  }

  public refreshToken(obj: IFormData) {
    const tokens = getAccessTokens();
    obj.scope = obj.scope.replace(/,/g, ' ');
    this.loginAndSetupTokenOnDevCenter(obj).then((token) => {
      if (Object.keys(token).length > 0) {
        const existingToken: IAccessToken = validateToken(obj);
        if (Object.keys(existingToken).length > 0) {
          const index = tokens.map((e) => e.accessToken).indexOf(existingToken.accessToken);
          tokens.splice(index, 1);
          tokens.push(token);
          window.localStorage.setItem('vendasta_tokens', JSON.stringify(tokens));
          this.snackbarService.openSuccessSnack('Access token refreshed', {
            duration: 3000,
          });
          window.setInterval(() => {
            window.location.reload();
          }, 3000);
        }
      } else {
        this.snackbarService.openErrorSnack('Access token failed to refresh', {
          duration: 3000,
        });
      }
    });
  }
}
