import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

interface SearchProps {
  searchTerm: string;
  options: string[];
}

@Injectable()
export class GalaxyTableSearchService {
  searchTerm = '';
  options: string[] = [];
  private fields$$ = new BehaviorSubject<SearchProps>({
    searchTerm: '',
    options: [],
  });
  fields$: Observable<SearchProps> = this.fields$$.asObservable();
  searchTerm$: Observable<string> = this.fields$$.pipe(
    map((fields) => fields.searchTerm),
    distinctUntilChanged(),
  );

  /**
   * Update search term value and propagate changes.
   * @param value - The new search term.
   */
  updateTerm(value: string): void {
    this.searchTerm = value;
    this.updateSearch();
  }

  /**
   * Propagate changes to consumers listening for search term and options changes.
   */
  private updateSearch(): void {
    this.fields$$.next({
      searchTerm: this.searchTerm,
      // Options are a future slice, when we have UI to support them
      options: this.options,
    });
  }
}
