import { ModuleWithProviders } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { MarkdownComponent } from './shared/markdown/markdown.component';
import { SecondaryNavigationComponent } from './shared/secondary-navigation/secondary-navigation.component';
import { HomeComponent } from './home/home.component';
import { StoplightComponent } from './shared/stoplight/stoplight.component';

export const routes: Route[] = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'partners',
    component: SecondaryNavigationComponent,
    data: {
      navItems: [
        {
          label: 'Overview',
          url: '/partners/overview',
        },
        {
          label: 'Single Sign-On (SSO)',
          url: '/partners/sso',
          children: [
            {
              label: 'Quickstart',
              url: '/partners/sso/quickstart',
            },
          ],
        },
        {
          label: 'Partner APIs',
          url: '/api',
        },
      ],
    },
    children: [
      {
        path: 'overview',
        redirectTo: '/platform/ZG9jOjEwMTkzMDg0-overview',
      },
      {
        path: 'sso',
        redirectTo: '/platform/ZG9jOjEwMTkzMDc5-overview',
      },
      {
        path: 'sso/quickstart',
        redirectTo: '/platform/ZG9jOjEwMTkzMDc5-overview',
      },
      {
        path: '**',
        redirectTo: '/platform/',
      },
    ],
  },
  {
    path: 'vendors',
    component: SecondaryNavigationComponent,
    data: {
      navItems: [
        {
          label: 'Vendor Center',
          url: 'https://vendors.vendasta.com',
        },
        {
          label: 'Getting Started',
          url: '/vendors/getting-started',
          children: [
            {
              label: 'Create Your Offerings',
              url: '/vendors/getting-started/offerings',
            },
            {
              label: 'Receiving Marketplace Payloads',
              url: '/vendors/getting-started/receive-payload',
            },
            {
              label: 'API Authentication',
              url: '/vendors/getting-started/authentication',
            },
            {
              label: 'First API Call',
              url: '/vendors/getting-started/calling-api',
            },
          ],
        },
        {
          label: 'Integration Guide',
          url: '/vendors/integration',
          children: [
            {
              label: 'Purchase Workflow',
              url: '/vendors/integration/purchase',
            },
            {
              label: 'OAuth2 Implementation',
              url: '/vendors/integration/oauth2',
            },
            {
              label: 'Session Transfer Implementation',
              url: '/vendors/integration/session-transfer',
            },
            {
              label: 'Reporting Implementation',
              url: '/vendors/integration/reporting',
            },
            {
              label: 'Activity Stream Implementation',
              url: '/vendors/integration/activity-stream',
            },
          ],
        },
        {
          label: 'Marketplace APIs',
          url: '/vendors/marketplace-api',
          children: [
            {
              label: 'Authentication',
              url: '/vendors/marketplace-api/authentication',
            },
            {
              label: 'Reference',
              url: 'https://developers.vendasta.com/swaggerui',
            },
          ],
        },
        {
          label: 'Marketplace Webhooks',
          url: '/vendors/webhooks',
        },
        {
          label: 'Apendix A: Integration Requirements',
          url: '/vendors/requirements',
        },
      ],
    },
    children: [
      {
        path: 'getting-started',
        redirectTo: '/vendor/ZG9jOjE3MTE3MDg1-overview',
      },
      {
        path: 'getting-started/offerings',
        redirectTo: '/vendor/ZG9jOjE3MTE3MDg3-configuring-your-products-and-services',
      },
      {
        path: 'getting-started/receive-payload',
        redirectTo: '/vendor/ZG9jOjE3MTE3MDg1-overview',
      },
      {
        path: 'getting-started/authentication',
        redirectTo: '/vendor/ZG9jOjE2NTY5Mzky-requirement-2-sso-o-auth2-3-legged-flow',
      },
      {
        path: 'getting-started/calling-api',
        redirectTo: '/vendor/ZG9jOjE3MTE3MDg1-overview',
      },
      {
        path: 'integration',
        redirectTo: '/vendor/ZG9jOjE3MTE3MDg2-integration-requirement-checklist',
      },
      {
        path: 'integration/purchase',
        redirectTo: '/vendor/ZG9jOjE2NTY5Mzkz-requirement-1-provisioning-workflow',
      },
      {
        path: 'integration/session-transfer',
        redirectTo: '/vendor/ZG9jOjE2NTY5NDEz-session-transfer-introduction',
      },
      {
        path: 'integration/oauth2',
        redirectTo: '/vendor/ZG9jOjE2NTY5Mzky-requirement-2-sso-o-auth2-3-legged-flow',
      },
      {
        path: 'integration/reporting',
        redirectTo: '/vendor/ZG9jOjE2NTY5Mzk0-requirement-3-reporting',
      },
      {
        path: 'integration/activity-stream',
        redirectTo: '/vendor/ZG9jOjIxNzMyNTMx-activity-stream-overview',
      },
      {
        path: 'marketplace-api',
        redirectTo: '/vendor/YXBpOjE2NTY5NDE0-marketplace-v1-api',
      },
      {
        path: 'marketplace-api/authentication',
        redirectTo: '/vendor/b3A6MTY1Njk0MzA-get-o-auth-token',
      },
      {
        path: 'web-hooks',
        redirectTo: 'webhooks',
      },
      {
        path: 'webhooks',
        redirectTo: '/vendor/ZG9jOjIxNzM0NjA3-marketplace-webhooks',
      },
      {
        path: 'requirements',
        redirectTo: '/vendor/ZG9jOjE3MTE3MDg2-integration-requirement-checklist',
      },
      {
        path: '**',
        redirectTo: '/vendor/',
      },
    ],
  },
  {
    path: 'api',
    component: SecondaryNavigationComponent,
    data: {
      navItems: [
        {
          label: 'Overview',
          url: '/api/legacy',
        },
        {
          label: 'Service Accounts for SDKs',
          url: '/api/service-accounts',
        },
        {
          label: 'Business SDK (Java)',
          url: '/api/business/v1/sdk/java',
        },
        {
          label: 'Sales SDK (Java)',
          url: '/api/sales/v1/sdk/java',
        },
        {
          label: 'Accounts SDK (Java)',
          url: '/api/accounts/v1/sdk/java',
        },
        {
          label: 'Snapshot SDK (Java)',
          url: '/api/snapshot/v1/sdk/java',
        },
        {
          label: 'Google Workspace SDK (PHP)',
          url: '/api/googleworkspace/v1/sdk/php',
        },
        {
          label: 'GoDaddy SDK (PHP)',
          url: '/api/godaddy/v1/sdk/php',
        },
        {
          label: 'Brand Analytics API',
          url: 'https://www-vendasta.appspot.com/documentation/brand-analytics/v2',
        },
        {
          label: 'Business Center API',
          url: 'https://www-vendasta.appspot.com/documentation/business-center',
        },
        {
          label: 'Partner Center API',
          url: 'https://www-vendasta.appspot.com/documentation/partner-central/v2',
        },
        {
          label: 'Listing Builder API',
          url: 'https://www-vendasta.appspot.com/documentation/presence-builder',
        },
        {
          label: 'Reputation Management API',
          url: 'https://www-vendasta.appspot.com/documentation/reputation-intelligence/v2',
        },
        {
          label: 'Social Marketing API',
          url: 'https://www-vendasta.appspot.com/documentation/social-marketing',
        },
        {
          label: 'Marketplace API',
          url: '/vendor/YXBpOjE2NTY5NDE0-marketplace-v1-api',
        },
      ],
    },
    children: [
      {
        path: 'legacy',
        component: MarkdownComponent,
        data: {
          repo: 'developer-center-client',
          path: 'src/assets/docs/legacy_overview.md',
        },
      },
      {
        path: 'service-accounts',
        component: MarkdownComponent,
        data: {
          repo: 'developer-center-client',
          path: 'src/assets/docs/service_accounts.md',
        },
      },
      {
        path: 'business/v1/sdk/java',
        component: MarkdownComponent,
        data: {
          repo: 'business',
          path: 'sdks/java/business_sdk/README.md',
        },
      },
      {
        path: 'sales/v1/sdk/java',
        component: MarkdownComponent,
        data: {
          repo: 'sales',
          path: 'sdks/java/sales_sdk/README.md',
        },
      },
      {
        path: 'accounts/v1/sdk/java',
        component: MarkdownComponent,
        data: {
          repo: 'accounts',
          path: 'sdks/java/accounts_sdk/README.md',
        },
      },
      {
        path: 'snapshot/v1/sdk/java',
        component: MarkdownComponent,
        data: {
          repo: 'snapshot',
          path: 'sdks/java/snapshot_sdk/README.md',
        },
      },
      {
        path: 'googleworkspace/v1/sdk/php',
        component: MarkdownComponent,
        data: {
          repo: 'g-suite-php-sdk',
          path: 'README.md',
        },
      },
      {
        path: 'godaddy/v1/sdk/php',
        component: MarkdownComponent,
        data: {
          repo: 'godaddy-php-sdk',
          path: 'README.md',
        },
      },
      {
        path: '**',
        redirectTo: 'legacy',
      },
    ],
  },
  {
    path: 'guides',
    component: SecondaryNavigationComponent,
    data: {
      navItems: [
        {
          label: 'Service Accounts',
          url: '/guides/service-accounts',
        },
      ],
    },
    children: [
      {
        path: '',
        component: MarkdownComponent,
        data: {
          repo: 'developer-center-client',
          path: 'src/assets/docs/guides/overview.md',
        },
      },
      {
        path: 'service-accounts',
        redirectTo: '/api/service-accounts',
      },
      {
        path: '**',
        redirectTo: '/platform/',
      },
    ],
  },
  {
    path: 'service-accounts',
    redirectTo: '/api/service-accounts',
  },
  {
    path: 'swaggerui',
    redirectTo: '/vendor/ZG9jOjE3MTE3MDg1-overview',
  },
  {
    path: 'products',
    component: StoplightComponent,
    data: {
      projectId: 'cHJqOjE1ODI5',
      basePath: 'products',
    },
    children: [{ path: '**', component: StoplightComponent }],
  },
  {
    path: 'platform',
    component: StoplightComponent,
    data: {
      projectId: 'cHJqOjE1ODI5',
      basePath: 'platform',
    },
    children: [{ path: '**', component: StoplightComponent }],
  },
  {
    path: 'vendor',
    component: StoplightComponent,
    data: {
      projectId: 'cHJqOjc1MTQ4',
      basePath: 'vendor',
    },
    children: [{ path: '**', component: StoplightComponent }],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
  paramsInheritanceStrategy: 'always',
});
