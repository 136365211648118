import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { GalaxyBadgeModule } from '@vendasta/galaxy/badge';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { BlankValueComponent } from './blank-value';

@Component({
  template: `
    <glxy-blank-value *ngIf="!value; else hasTags"></glxy-blank-value>

    <ng-template #hasTags>
      <div class="tags" *ngIf="value.length > 0">
        <glxy-badge [glxyTooltip]="value.join(', ')">{{ value[0] }}</glxy-badge>
        <glxy-badge *ngIf="value.length === 2" [glxyTooltip]="value.join(', ')">{{ value[1] }}</glxy-badge>
        <glxy-badge *ngIf="value.length > 2" [glxyTooltip]="value.join(', ')">+{{ value.length - 1 }}</glxy-badge>
      </div>
    </ng-template>
  `,
  styleUrls: ['./tags.scss'],
  imports: [CommonModule, BlankValueComponent, GalaxyBadgeModule, GalaxyTooltipModule],
  standalone: true,
})
export class TagsRendererComponent {
  value: string[] = [];
}
