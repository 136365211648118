import { Component, Inject, Input } from '@angular/core';
import { AdvancedColumnOrganizerService } from './advanced-column-organizer.service';
import { ColumnInfo } from './advanced-column-organizer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { firstValueFrom, Observable, map, Subscription } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { GalaxyColumnDef } from '../../../table.interface';
import { UntypedFormControl } from '@angular/forms';

// @dynamic
@Component({
  selector: 'glxy-table-advanced-column',
  templateUrl: './advanced-column-organizer.component.html',
  styleUrls: ['./advanced-column-organizer.component.scss'],
  providers: [AdvancedColumnOrganizerService],
})
export class AdvancedColumnOrganizerComponent {
  saveName: string;
  activeTab: string | null = null;
  selectedColumns: string[] = [];
  columnDefs: GalaxyColumnDef[] = [];

  @Input() advancedColumns: GalaxyColumnDef[] = [];

  public searchControl: UntypedFormControl = new UntypedFormControl();
  private readonly searchSubscription: Subscription;

  constructor(
    public advancedColumnOrganizerService: AdvancedColumnOrganizerService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      saveName: string;
      columnConfigs: GalaxyColumnDef[];
      groupConfigs: GalaxyColumnDef[];
      useI18NTranslations: boolean;
    },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<AdvancedColumnOrganizerComponent>,
  ) {
    this.saveName = dialogData.saveName;
    this.loadColumns();
    this.getActiveTab();
    this.searchSubscription = this.searchControl.valueChanges.subscribe((searchTerm) => {
      this.updateSearchTerm(searchTerm);
    });
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  get selectedColumns$(): Observable<ColumnInfo[]> {
    return this.advancedColumnOrganizerService.selectedColumns$;
  }

  get pinnedLeftColumns$(): Observable<ColumnInfo[]> {
    return this.advancedColumnOrganizerService.pinnedLeftColumns$;
  }

  get pinnedRightColumns$(): Observable<ColumnInfo[]> {
    return this.advancedColumnOrganizerService.pinnedRightColumns$;
  }

  get searchedGroups$(): Observable<GalaxyColumnDef[]> {
    return this.advancedColumnOrganizerService.searchedGroups$;
  }

  updateSearchTerm(event: string): void {
    this.advancedColumnOrganizerService.updateSearchTerm(event);
  }

  setColumnVisibility(id: string, isVisible: boolean): void {
    this.advancedColumnOrganizerService.setColumnVisibility(id, isVisible);
  }

  columnDroppedInList(event: CdkDragDrop<any>): void {
    this.advancedColumnOrganizerService.columnDroppedInList(event.item.data.columnIndex, event.currentIndex);
  }

  async save(): Promise<void> {
    this.selectedColumns = this.advancedColumnOrganizerService.getSelectedColumns();
    this.columnDefs = await firstValueFrom(this.advancedColumnOrganizerService.getSelectedColumnsDef$());
    this.dialogRef.close(true);
  }

  loadColumns(): void {
    this.advancedColumnOrganizerService.configure(this.dialogData.columnConfigs, this.dialogData.groupConfigs);
  }

  selectAll(columnGroup: GalaxyColumnDef): void {
    this.advancedColumnOrganizerService.selectAll(columnGroup);
  }

  deselectAll(columnGroup: GalaxyColumnDef): void {
    this.advancedColumnOrganizerService.deselectAll(columnGroup);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  isMobileScreen(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 820px)');
  }

  setActiveTab(currentTab: string): void {
    sessionStorage.setItem('columnManagerCurrentTab', currentTab);
  }
  getActiveTab(): void {
    this.activeTab = sessionStorage.getItem('columnManagerCurrentTab');
  }
  isActionsColumn(column: GalaxyColumnDef): Observable<boolean> {
    return this.advancedColumnOrganizerService.selectedOrderedColumns$.pipe(
      map((selectedCols) => {
        if (column.id === 'actions') {
          return true;
        }
        return selectedCols.includes(column.id);
      }),
    );
  }
}
