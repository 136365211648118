import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GalaxyCheckboxModule } from '@vendasta/galaxy/checkbox';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { GalaxyInputModule } from '@vendasta/galaxy/input';
import { GalaxyLoadingSpinnerModule } from '@vendasta/galaxy/loading-spinner';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { GalaxyPopoverModule } from '@vendasta/galaxy/popover';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { ColumnSortSimpleComponent } from './components/column-sort/column-sort-simple/column-sort-simple.component';
import { GalaxyColumnSortComponent } from './components/column-sort/column-sort.component';
import { ModelDrivenCellComponent } from './components/model-driven-cell/model-driven-cell.component';
import { BlankValueComponent } from './components/model-driven-cell/renderers/blank-value';
import { BooleanRendererComponent } from './components/model-driven-cell/renderers/boolean';
import { DateRendererComponent } from './components/model-driven-cell/renderers/date';
import { IntegerRendererComponent } from './components/model-driven-cell/renderers/integer';
import { FloatRendererComponent } from './components/model-driven-cell/renderers/float';
import { PhoneRendererComponent } from './components/model-driven-cell/renderers/phone';
import { TextRendererComponent } from './components/model-driven-cell/renderers/text';
import { TimestampRendererComponent } from './components/model-driven-cell/renderers/timestamp';
import { GalaxySelectionComponent } from './components/selection/selection.component';
import { TableContainerComponent } from './components/table-container/table-container.component';
import { TableContentFooterComponent } from './components/table-content-footer/table-content-footer.component';
import { TableContentHeaderComponent } from './components/table-content-header/table-content-header.component';
import { AdvancedColumnOrganizerButtonComponent } from './components/column-sort/advanced-column-organizer/advanced-column-organizer-button.component';
import { AdvancedColumnOrganizerComponent } from './components/column-sort/advanced-column-organizer/advanced-column-organizer.component';
import { MatDialogActions, MatDialogContent } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { GridViewComponent } from './components/grid-view/grid-view.component';
import { GalaxyGridCardDirective } from './components/grid-view/grid-card.directive';
import { GalaxyGridMinCardWidthDirective } from './components/grid-view/grid-min-width.directive';

export { GalaxyColumnSortComponent } from './components/column-sort/column-sort.component';
export * from './components/model-driven-cell/interface';
export { BlankValueComponent } from './components/model-driven-cell/renderers/blank-value';
export { GalaxySelectionComponent } from './components/selection/selection.component';
export { TableContainerComponent } from './components/table-container/table-container.component';
export { TableContentHeaderComponent } from './components/table-content-header/table-content-header.component';
export { GalaxyDataSource } from './datasource/datasource';
export * from './datasource/datasource.interface';
export { GalaxyColumnsSortService } from './services/column-sort.service';
export { GalaxyColumnDef } from './table.interface';

export const MODULE_DECLARATIONS = [
  TableContainerComponent,
  TableContentHeaderComponent,
  TableContentFooterComponent,
  GalaxyColumnSortComponent,
  ColumnSortSimpleComponent,
  GalaxySelectionComponent,
  ModelDrivenCellComponent,
  DateRendererComponent,
  IntegerRendererComponent,
  FloatRendererComponent,
  TextRendererComponent,
  BooleanRendererComponent,
  PhoneRendererComponent,
  TimestampRendererComponent,
  AdvancedColumnOrganizerButtonComponent,
  AdvancedColumnOrganizerComponent,
  GridViewComponent,
  GalaxyGridCardDirective,
  GalaxyGridMinCardWidthDirective,
];

export const MODULE_IMPORTS = [
  CommonModule,
  GalaxyInputModule,
  GalaxyCheckboxModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  GalaxyCheckboxModule,
  FormsModule,
  ReactiveFormsModule,
  GalaxyPopoverModule,
  DragDropModule,
  MatCheckboxModule,
  TranslateModule,
  MatPaginatorModule,
  MatTableModule,
  RouterModule,
  GalaxyTooltipModule,
  GalaxyPipesModule,
  BlankValueComponent,
  GalaxyFormFieldModule,
  GalaxyLoadingSpinnerModule,
  MatSortModule,
  MatDialogContent,
  MatDialogActions,
  MatIconModule,
  MatTabsModule,
  MatDialogActions,
  MatCheckboxModule,
  MatButtonToggleGroup,
  MatButtonToggle,
];

export const MODULE_EXPORTS = [
  TableContainerComponent,
  TableContentHeaderComponent,
  GalaxySelectionComponent,
  GalaxyColumnSortComponent,
  GridViewComponent,
  GalaxyGridCardDirective,
  GalaxyGridMinCardWidthDirective,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: [MODULE_IMPORTS],
  exports: MODULE_EXPORTS,
})
export class GalaxyTableModule {}
