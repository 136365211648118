// *********************************
// Code generated by sdkgen
// DO NOT EDIT!.
//
// API Service.
// *********************************
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { HostService } from '../_generated/host.service';
import {
  ListEventsRequestInterface,
  ListEventsResponseInterface,
  ListMicroservicesRequestInterface,
  ListMicroservicesResponseInterface,
  ListSymbolsRequestInterface,
  ListSymbolsResponseInterface,
} from './interfaces/';
import {
  ListEventsRequest,
  ListEventsResponse,
  ListMicroservicesRequest,
  ListMicroservicesResponse,
  ListSymbolsRequest,
  ListSymbolsResponse,
} from './objects/';

@Injectable()
export class DeveloperCenterApiService {
  constructor(private http: HttpClient, private hostService: HostService) {}

  private apiOptions(): { headers: HttpHeaders; withCredentials: boolean } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
  }

  listMicroservices(
    r: ListMicroservicesRequest | ListMicroservicesRequestInterface,
  ): Observable<ListMicroservicesResponse> {
    const request = (<ListMicroservicesRequest>r).toApiJson
      ? <ListMicroservicesRequest>r
      : new ListMicroservicesRequest(r);
    return this.http
      .post<ListMicroservicesResponseInterface>(
        this.hostService.hostWithScheme() + '/developercenter.v1.DeveloperCenter/ListMicroservices',
        request.toApiJson(),
        this.apiOptions(),
      )
      .pipe(
        map((resp) => ListMicroservicesResponse.fromProto(resp)),
        share(),
      );
  }
  listSymbols(r: ListSymbolsRequest | ListSymbolsRequestInterface): Observable<ListSymbolsResponse> {
    const request = (<ListSymbolsRequest>r).toApiJson ? <ListSymbolsRequest>r : new ListSymbolsRequest(r);
    return this.http
      .post<ListSymbolsResponseInterface>(
        this.hostService.hostWithScheme() + '/developercenter.v1.DeveloperCenter/ListSymbols',
        request.toApiJson(),
        this.apiOptions(),
      )
      .pipe(
        map((resp) => ListSymbolsResponse.fromProto(resp)),
        share(),
      );
  }
  listEvents(r: ListEventsRequest | ListEventsRequestInterface): Observable<ListEventsResponse> {
    const request = (<ListEventsRequest>r).toApiJson ? <ListEventsRequest>r : new ListEventsRequest(r);
    return this.http
      .post<ListEventsResponseInterface>(
        this.hostService.hostWithScheme() + '/developercenter.v1.DeveloperCenter/ListEvents',
        request.toApiJson(),
        this.apiOptions(),
      )
      .pipe(
        map((resp) => ListEventsResponse.fromProto(resp)),
        share(),
      );
  }
}
