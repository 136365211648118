import { ChangeDetectionStrategy, Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { CellData, CustomCellComponent } from './interface';
import { BlankValueRendererComponent } from './renderers/blank-value';
import { BooleanRendererComponent } from './renderers/boolean';
import { DateRendererComponent } from './renderers/date';
import { IntegerRendererComponent } from './renderers/integer';
import { PhoneRendererComponent } from './renderers/phone';
import { TagsRendererComponent } from './renderers/tags';
import { TextRendererComponent } from './renderers/text';
import { TimestampRendererComponent } from './renderers/timestamp';
import { FloatRendererComponent } from './renderers/float';

@Component({
  selector: 'glxy-table-model-driven-cell',
  template: '<ng-container></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelDrivenCellComponent implements OnInit {
  @Input() cellData: CellData;

  constructor(private viewContainerRef: ViewContainerRef) {}

  private renderCell(): void {
    this.viewContainerRef.clear();
    const cellType = this.cellData?.cellType;
    if (cellType === 'integer') {
      const component = this.viewContainerRef.createComponent<IntegerRendererComponent>(IntegerRendererComponent);
      component.instance.value = Number(this.cellData?.value as number);
    } else if (cellType === 'float') {
      const component = this.viewContainerRef.createComponent<FloatRendererComponent>(FloatRendererComponent);
      component.instance.value = Number(this.cellData?.value as number);
    } else if (cellType === 'date') {
      const component = this.viewContainerRef.createComponent<DateRendererComponent>(DateRendererComponent);
      component.instance.value = (this.cellData?.value as Date) || new Date(0);
    } else if (cellType === 'text') {
      const component = this.viewContainerRef.createComponent<TextRendererComponent>(TextRendererComponent);
      component.instance.value = (this.cellData?.value as string) || '';
      if (this.cellData && 'link' in this.cellData) {
        component.instance.link = this.cellData.link;
      }
      if (this.cellData && 'action' in this.cellData) {
        component.instance.action = this.cellData.action;
      }
    } else if (cellType === 'boolean') {
      const component = this.viewContainerRef.createComponent<BooleanRendererComponent>(BooleanRendererComponent);
      component.instance.value = Boolean(this.cellData?.value);
    } else if (cellType === 'tags') {
      const component = this.viewContainerRef.createComponent<TagsRendererComponent>(TagsRendererComponent);
      component.instance.value = (this.cellData?.value as string[]) || new Array<string>();
    } else if (cellType === 'phone') {
      const component = this.viewContainerRef.createComponent<PhoneRendererComponent>(PhoneRendererComponent);
      component.instance.value = (this.cellData?.value as string) || '';
    } else if (cellType === 'timestamp') {
      const component = this.viewContainerRef.createComponent<TimestampRendererComponent>(TimestampRendererComponent);
      component.instance.value = (this.cellData?.value as Date) || new Date(0);
    } else if (cellType === 'custom' && this.cellData && 'component' in this.cellData) {
      const component = this.viewContainerRef.createComponent<CustomCellComponent>(this.cellData.component);
      component.instance.value = this.cellData.value;
    } else {
      this.viewContainerRef.createComponent<BlankValueRendererComponent>(BlankValueRendererComponent);
    }
  }

  ngOnInit(): void {
    this.renderCell();
  }
}
