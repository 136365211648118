import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, shareReplay, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { PagedResponseInterface, PageEvent, PaginatedAPIInterface } from './datasource.interface';
import { INDETERMINATE_MAX } from './paginator-text';

interface State<RowType, FilterType, SortType> {
  pageSize?: number;
  cursor?: string;
  pageIndex?: number;
  previousPageIndex?: number;
  dataMembers?: RowType[];
  shouldRefetch?: boolean;
  loading?: boolean;
  totalDataMembers?: number;
  searchTerm?: string;
  searchFields?: string[];
  filters?: FilterType[];
  sorting?: SortType[];
  shouldClearSelection?: boolean;
  initialLoadCompleted?: boolean;
  selectAll?: boolean;
}

export class GalaxyDataSource<RowType, FilterType = void, SortType = void> extends DataSource<RowType> {
  private stateSubscription?: Subscription | null;
  private cursors: Record<number, string> = {};
  private searchTerm = '';

  private state$$ = new BehaviorSubject<State<RowType, FilterType, SortType>>(this.defaultState());
  state$ = this.state$$.asObservable();
  loading$ = this.state$.pipe(map(({ loading }) => loading));
  shouldClearSelection$ = this.state$.pipe(map(({ shouldClearSelection }) => shouldClearSelection));
  totalDataMembers$ = this.state$.pipe(map(({ totalDataMembers }) => totalDataMembers));
  pageIndex$ = this.state$.pipe(map(({ pageIndex }) => pageIndex));
  dataMembers$ = this.state$.pipe(map(({ dataMembers = [] }) => dataMembers));
  selectAll$ = this.state$.pipe(map(({ selectAll }) => selectAll));
  clearSelectionOnChange = false;
  get state(): State<RowType, FilterType, SortType> {
    return this.state$$.getValue();
  }

  set state(newState: State<RowType, FilterType, SortType>) {
    this.state$$.next({ ...this.state, ...newState });
  }

  filtersApplied$: Observable<boolean> = this.state$$.pipe(
    map(({ filters }) => {
      return filters ? filters.length > 0 : false;
    }),
  );

  constructor(private listApi: PaginatedAPIInterface<RowType, FilterType, SortType>) {
    super();
  }

  /**
   * Set the current search term and refetch data for the current page.
   * Also starts the back to page one as we can't start at a random page with new search criteria.
   * @param searchTerm - Term to search for
   */
  setSearchTerm(searchTerm: string): void {
    const state = this.state;
    this.searchTerm = searchTerm;

    this.state = {
      ...this.defaultState(),
      searchTerm,
      filters: state.filters,
      pageSize: state.pageSize,
      loading: true,
      shouldRefetch: true,
      selectAll: false,
      shouldClearSelection: this.clearSelectionOnChange,
    };
  }

  /**
   * Set the current filters term and refetch data.
   * Also starts the back to page one as we can't start at a random page with new filters criteria.
   * @param filters
   */
  setFilters(filters: FilterType[]): void {
    const state = this.state;
    this.clearCursors();
    this.state = {
      ...this.defaultState(),
      filters,
      searchTerm: state.searchTerm,
      pageSize: state.pageSize,
      loading: true,
      shouldRefetch: true,
      selectAll: false,
      shouldClearSelection: this.clearSelectionOnChange,
    };
  }

  /**
   * Set the current sorting term and refetch data.
   * Also starts the back to page one as we can't start at a random page with new filters criteria.
   * @param sorting
   */
  setSorting(sorting: SortType[]): void {
    this.clearCursors();
    this.state = {
      ...this.defaultState(),
      sorting,
      pageSize: this.state.pageSize,
      loading: true,
      shouldRefetch: true,
    };
  }

  /**
   * Triggered by the paginator, this handles page changes events, as well as page size changes.
   * @param page - Page event triggered by Paginator
   */
  pageChanged(page: PageEvent): void {
    const state = this.state;
    if (page.pageSize !== state.pageSize) {
      this.pageSizeChanged(page.pageSize);
      return;
    }

    // Advance to the next/previous page
    this.state = {
      ...this.state,
      shouldRefetch: true,
      pageIndex: page.pageIndex,
      loading: true,
    };
  }

  pageSizeChanged(pageSize: number): void {
    const state = this.state;

    this.state = {
      ...this.defaultState(),
      pageSize: pageSize,
      loading: true,
      shouldRefetch: true,
      // persist searching, sorting, and filtering
      searchTerm: state.searchTerm,
      searchFields: state.searchFields,
      sorting: state.sorting,
      filters: state.filters,
    };
  }

  clearSelection(): void {
    this.state = {
      ...this.state,
      shouldRefetch: true,
      loading: true,
      shouldClearSelection: true,
      selectAll: false,
    };
  }

  toggleSelectAll(): void {
    this.state = {
      ...this.state,
      selectAll: !this.state.selectAll,
      shouldClearSelection: this.state.selectAll,
    };
  }

  /**
   * Invoked by the paginator when it first connects to the data source.
   */
  connect(): Observable<RowType[]> {
    // Whenever state changes and we should refetch data, call the api with the current pageSize and cursor.
    // We could consider adding a debounce or something here but our API can handle some extra requests.
    // Start the subscription here so we can unsubscribe/subscribe on disconnect/connect.
    this.stateSubscription = this.state$
      .pipe(
        filter((state) => !!state.shouldRefetch),
        distinctUntilChanged(),
        switchMap(
          ({ pageSize = this.DEFAULT_PAGE_SIZE, pageIndex = 0, searchTerm, searchFields, filters, sorting }) => {
            const cursor = this.getCursor(pageIndex, pageSize);
            const req = {
              pagingOptions: {
                pageSize,
                cursor: cursor,
              },
              searchOptions: {
                text: searchTerm?.trim() || '',
                fields: searchFields || [],
              },
              filters: filters,
              sorting: sorting,
            };
            return this.listApi.get(req);
          },
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
      )
      .subscribe({
        next: (response) => this.pageDataRecieved(response),
      });

    return this.dataMembers$;
  }

  /**
   * Triggered when the paginator is cleaned up.
   */
  disconnect(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  /**
   * Handle a paged response from the API
   * @param response - The paged response data
   */
  pageDataRecieved(response: PagedResponseInterface<RowType>): void {
    const { pageIndex = 0, pageSize = this.DEFAULT_PAGE_SIZE } = this.state;
    // Figure out if we can determine how many pages there are
    let totalDataMembers = INDETERMINATE_MAX;
    if (response.pagingMetadata.totalResults) {
      totalDataMembers = response.pagingMetadata.totalResults;
    } else if (
      response.data.length < pageSize ||
      response.data.length === 0 ||
      response.pagingMetadata.hasMore === false
    ) {
      totalDataMembers = pageIndex * pageSize + response.data.length;
    }

    this.setNextCursor(pageIndex, pageSize, response.pagingMetadata.nextCursor);

    // This provides resiliency around frontend data changes that
    // can incur many different state changes in a single frame.
    requestAnimationFrame(() => {
      this.state = {
        ...this.state,
        totalDataMembers,
        cursor: response.pagingMetadata.nextCursor,
        dataMembers: [...response.data],
        shouldRefetch: false,
        loading: false,
        shouldClearSelection: false,
        initialLoadCompleted: true,
      };
    });
  }

  DEFAULT_PAGE_SIZE = 20;

  private defaultState(): State<RowType, FilterType, SortType> {
    if (this.state$$ === undefined || this.state$$.getValue().initialLoadCompleted === true) {
      return {
        pageSize: 20,
        cursor: '',
        previousPageIndex: 0,
        pageIndex: 0,
        dataMembers: [],
        shouldRefetch: true,
        loading: false,
        totalDataMembers: INDETERMINATE_MAX,
        searchTerm: this.searchTerm,
        searchFields: [],
        filters: [],
        sorting: [],
      };
    }
    return {};
  }

  clearCursors(): void {
    this.cursors = {};
  }

  getCursor(pageIndex: number, pageSize: number): string {
    return this.cursors[pageIndex * pageSize] || '';
  }

  getCursors(): Record<number, string> {
    return this.cursors;
  }

  loadCursors(cursors: Record<number, string>): void {
    this.cursors = cursors;
  }

  private setNextCursor(pageIndex: number, pageSize: number, cursor: string): void {
    const nextPageIndex = pageIndex + 1;
    this.cursors[nextPageIndex * pageSize] = cursor;
  }
}
