<mat-paginator
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="_pageSize"
  (page)="pageChanged($event)"
  [length]="(dataSource.totalDataMembers$ | async) ?? 0"
  [disabled]="!!(dataSource.loading$ | async)"
  [pageIndex]="dataSource.pageIndex$ | async"
></mat-paginator>

<!--
    PAGINATIOR

    - pass through page options
    - provide the parent an API service
        - List API comes with
        - Should have other APIs too
    - "of Many" forever
        - not "of inifinity"
    - Search and filters should be put in here, via the service
        - IE) on "get" it recieves search, filters, sort, etc as fields
-->
