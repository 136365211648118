<ng-content select="glxy-table-content-header"></ng-content>
<div
  class="table-loading--wrapper"
  [ngClass]="{
    'table-is-loading': dataSource.loading$ | async
  }"
>
  <div
    matSort
    (matSortChange)="updateSorting($event)"
    class="glxy-table-scrollable show-sticky-shadow-end"
    #scrollContainer
  >
    <div [hidden]="currentDisplayOption === 'grid'">
      <ng-content select=":not([glxy-grid-content])"></ng-content>

      <ng-container *ngFor="let col of columns" [matColumnDef]="col.id">
        <ng-container *ngIf="col.sortable; else noSort">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ col.title }}</th>
        </ng-container>
        <ng-template #noSort>
          <th mat-header-cell *matHeaderCellDef>{{ col.title }}</th>
        </ng-template>
        <td mat-cell *matCellDef="let row">
          <glxy-table-model-driven-cell [cellData]="getCellData(row.data, col.id)"></glxy-table-model-driven-cell>
        </td>
      </ng-container>
    </div>
    <div [hidden]="currentDisplayOption === 'list'">
      <glxy-table-grid-view [minWidth]="gridCardMinWidth" [cardDirective]="cardDirective">
        <ng-content select="[glxy-grid-content]"></ng-content>
      </glxy-table-grid-view>
    </div>
  </div>
  <div class="loading-message" *ngIf="dataSource.loading$ | async">
    <glxy-loading-spinner class="loading-spinner" size="large" fullHeight fullWidth></glxy-loading-spinner>
  </div>
</div>
<glxy-table-content-footer
  *ngIf="showFooter"
  [id]="id"
  [dataSource]="dataSource"
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="pageSize"
></glxy-table-content-footer>
