import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { EnvironmentService } from '@galaxy/core';
import { ProductAnalyticsService } from '@vendasta/product-analytics';

declare let deployment: string; // Stamped down by vStatic

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav')
  private secondaryNavigation: MatSidenav;

  constructor(
    private productAnalyticsService: ProductAnalyticsService,
    private environmentService: EnvironmentService,
  ) {}

  toggle(): void {
    this.secondaryNavigation.toggle();
  }

  ngOnInit(): void {
    if (typeof deployment !== 'undefined') {
      this.productAnalyticsService.initialize({
        environment: this.environmentService.getEnvironment(),
        projectUUID: '2eb27a8d-a016-40d5-93d9-4d527395352d',
        postHogID: 'phc_VrKjqkTmTr9Swvtr1hAoyT44WEfnSr5SwVWy2gCtZoL',
        projectName: 'developer-center-client',
      });
    }
  }
}
