import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { LexiconModule } from '@galaxy/lexicon';
import { GalaxyEmptyStateModule } from '@vendasta/galaxy/empty-state';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { GalaxyPipesModule } from '@vendasta/galaxy/pipes';
import { GalaxyPopoverModule } from '@vendasta/galaxy/popover';
import { GalaxySnackbarModule } from '@vendasta/galaxy/snackbar-service';
import { GalaxyTableModule } from '@vendasta/galaxy/table';
import { GalaxyTooltipModule } from '@vendasta/galaxy/tooltip';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { MarkdownModule } from 'ngx-markdown';
import En from '../../assets/i18n/en.json';
import { AccessTokenDialogFooterComponent } from './access-token-dialog-footer/access-token-dialog-footer.component';
import { AccessTokenComponent, storageFactory } from './access-token/access-token.component';
import { DeveloperCenterModule } from './developer_center_sdk/src/lib/developer-center.module';
import { FooterComponent } from './footer/footer.component';
import { MarkdownComponent } from './markdown/markdown.component';
import { PrimaryNavigationComponent } from './primary-navigation/primary-navigation.component';
import { SecondaryNavigationComponent } from './secondary-navigation/secondary-navigation.component';
import { StoplightComponent } from './stoplight/stoplight.component';
import { TocComponent } from './toc/toc.component';

@NgModule({
  declarations: [
    TocComponent,
    MarkdownComponent,
    SecondaryNavigationComponent,
    FooterComponent,
    PrimaryNavigationComponent,
    StoplightComponent,
    AccessTokenComponent,
    AccessTokenDialogFooterComponent,
  ],
  imports: [
    BrowserModule,
    MarkdownModule.forChild(),
    DeveloperCenterModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatInputModule,
    RouterModule.forChild([]),
    ObserversModule,
    CommonModule,
    MatButtonModule,
    GalaxyPopoverModule,
    FormsModule,
    MatDialogModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatCardModule,
    RouterModule.forRoot([]),
    MatTableModule,
    GalaxyTooltipModule,
    MatTooltipModule,
    GalaxyTableModule,
    GalaxyPageModule,
    MatMenuModule,
    MatCheckboxModule,
    GalaxyPageModule,
    CommonModule,
    GalaxyTableModule,
    GalaxyEmptyStateModule,
    MatIconModule,
    MatTableModule,
    GalaxyPipesModule,
    MatPaginatorModule,
    LexiconModule.forChild({
      componentName: 'common/observatory',
      baseTranslation: En,
    }),
    GalaxyFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    GalaxySnackbarModule,
    DragDropModule,
  ],
  exports: [TocComponent, PrimaryNavigationComponent, SecondaryNavigationComponent, FooterComponent],
  providers: [{ provide: OAuthStorage, useFactory: storageFactory }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
