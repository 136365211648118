<div *ngIf="type === 'simple'">
  <button
    mat-stroked-button
    class="table-btn"
    [glxyPopover]="simpleSort"
    (click)="isOpen = true"
    data-action="clicked-glxy-table-columns-button"
  >
    <span class="table-btn-icon"><mat-icon>view_column</mat-icon></span>
    <span class="hide-on-small">Columns</span>
  </button>

  <glxy-popover
    #simpleSort
    [isOpen]="isOpen"
    [hasBackdrop]="true"
    [showBackdrop]="false"
    (backdropClick)="isOpen = false"
    [hasArrow]="true"
    [maxHeight]="'100%'"
    [padding]="'none'"
    [positions]="[PopoverPositions.BottomRight]"
  >
    <glxy-column-sort-simple [columns]="columns$ | async"></glxy-column-sort-simple>
  </glxy-popover>
</div>
<div *ngIf="type === 'advanced'">
  <glxy-mat-table-advanced-column-organizer-button
    [groupConfigs]="groupConfigs$ | async"
    [columnConfigs]="columns$ | async"
    data-action="clicked-glxy-table-columns-button"
  >
    <span class="table-btn-icon"><mat-icon>view_column</mat-icon></span>
    <span class="hide-on-small">Columns</span>
  </glxy-mat-table-advanced-column-organizer-button>
</div>
