import { Injectable } from '@angular/core';
import { ColumnInfo, Pinned } from './advanced-column-organizer';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { GalaxyColumnDef } from '../../../table.interface';

@Injectable()
export class AdvancedColumnOrganizerService {
  private searchTerm$$ = new BehaviorSubject<string>('');
  searchTerm$: Observable<string> = this.searchTerm$$.asObservable();

  private columnDefs$$ = new BehaviorSubject<GalaxyColumnDef[]>([]);
  columnDefs$: Observable<GalaxyColumnDef[]> = this.columnDefs$$.asObservable();

  private groupDefs$$ = new BehaviorSubject<GalaxyColumnDef[]>([]);
  groupDefs$: Observable<GalaxyColumnDef[]> = this.groupDefs$$.asObservable();

  private selectedOrderedColumns$$ = new BehaviorSubject<string[]>([]);

  selectedOrderedColumns$: Observable<string[]> = this.selectedOrderedColumns$$.asObservable();

  columnDefsByID$: Observable<{ [id: string]: GalaxyColumnDef }>;
  selectedColumns$: Observable<ColumnInfo[]>;
  searchedGroups$: Observable<GalaxyColumnDef[]>;
  pinnedLeftColumns$: Observable<ColumnInfo[]>;
  pinnedRightColumns$: Observable<ColumnInfo[]>;

  translator: TranslateService;

  constructor(translator: TranslateService) {
    this.translator = translator;
    this.columnDefsByID$ = this.columnDefs$.pipe(
      map((columnDefs) => {
        const columnDefsByID: { [id: string]: GalaxyColumnDef } = {};
        columnDefs.map((cd) => {
          columnDefsByID[cd.id] = cd;
        });
        return columnDefsByID;
      }),
    );

    this.selectedColumns$ = this.getSelectedColumns$();
    this.searchedGroups$ = this.getSearchedGroups$();
    this.pinnedLeftColumns$ = this.getPinnedLeftColumns$();
    this.pinnedRightColumns$ = this.getPinnedRightColumns$();
  }

  configure(colDefs: GalaxyColumnDef[], groupDefs: GalaxyColumnDef[]): void {
    colDefs = (colDefs || []).map((cd) => {
      cd.title = this.translator.instant(cd.title || cd.id);
      return cd;
    });
    groupDefs = (groupDefs || []).map((gd) => {
      gd.title = this.translator.instant(gd.title || gd.id);
      return gd;
    });

    this.columnDefs$$.next(colDefs || []);
    this.groupDefs$$.next(groupDefs || []);
    const selectedOrderedColumns: string[] = this.columnDefs$$.value
      .filter((c) => !c.pinned && !c.hidden)
      .map((c) => c.id);
    this.selectedOrderedColumns$$.next(selectedOrderedColumns);
  }

  getPinnedLeftColumns$(): Observable<ColumnInfo[]> {
    return this.pinnedColumns$(Pinned.PINNED_LEFT);
  }

  getSelectedColumns$(): Observable<ColumnInfo[]> {
    return combineLatest([this.selectedOrderedColumns$, this.columnDefsByID$]).pipe(
      map(([selectedColumnIDs, keyedColumns]): ColumnInfo[] => {
        return selectedColumnIDs.map((id: string): ColumnInfo => {
          return {
            id: id,
            name: keyedColumns[id].title || keyedColumns[id].id,
            isVisible: !keyedColumns[id]?.hidden,
            pinned: Pinned.PINNED_UNSET,
          };
        });
      }),
    );
  }

  getSelectedColumnsDef$(): Observable<GalaxyColumnDef[]> {
    return combineLatest([this.selectedOrderedColumns$, this.columnDefs$]).pipe(
      map(([selectedOrderedColumns, columnsEntry]) => {
        const pinnedColumns = new Map<number, GalaxyColumnDef>();
        columnsEntry.forEach((column, index) => {
          if (column.pinned) {
            pinnedColumns.set(index, column);
          }
        });

        const nonPinnedColumns = columnsEntry.filter((column) => !column.pinned);

        const sortedNonPinned = nonPinnedColumns.sort(
          (a, b) => selectedOrderedColumns.indexOf(a.id) - selectedOrderedColumns.indexOf(b.id),
        );

        const orderedColumns: GalaxyColumnDef[] = [];

        columnsEntry.forEach((column, index) => {
          if (pinnedColumns.has(index)) {
            orderedColumns[index] = pinnedColumns.get(index)!;
          } else {
            orderedColumns[index] = sortedNonPinned.shift() as GalaxyColumnDef;
          }
        });

        orderedColumns.forEach((column) => {
          if (!column.pinned) {
            column.hidden = !selectedOrderedColumns.includes(column.id);
          }
        });

        return orderedColumns;
      }),
    );
  }

  getPinnedRightColumns$(): Observable<ColumnInfo[]> {
    return this.pinnedColumns$(Pinned.PINNED_RIGHT);
  }

  pinnedColumns$(pinSide: Pinned): Observable<ColumnInfo[]> {
    return this.columnDefs$.pipe(
      map((columnDefs: GalaxyColumnDef[]): ColumnInfo[] => {
        const pinnedColumns = columnDefs.filter((columnDef) => {
          if (pinSide === Pinned.PINNED_LEFT) {
            return columnDef.pinned && columnDef.sticky;
          }
          if (pinSide === Pinned.PINNED_RIGHT) {
            return columnDef.pinned && columnDef.stickyEnd;
          }
          return false;
        });

        return pinnedColumns.map(
          (columnDef: GalaxyColumnDef): ColumnInfo => ({
            id: columnDef.id,
            name: columnDef.title || columnDef.id,
            isVisible: true,
            pinned: pinSide,
          }),
        );
      }),
    );
  }

  private organizeColumnsByGroup(
    keyedColumns: { [id: string]: GalaxyColumnDef },
    groupDefs: GalaxyColumnDef[],
  ): GalaxyColumnDef[] {
    return groupDefs.map((groupDef: GalaxyColumnDef): GalaxyColumnDef => {
      const filteredColumns: (GalaxyColumnDef | undefined)[] = (groupDef.columns ?? [])
        .map((columnID): GalaxyColumnDef | undefined => {
          const id = columnID?.id;
          const title = keyedColumns[id]?.title || id;
          const pinned = keyedColumns[id]?.pinned;

          return id ? { id, title, pinned } : undefined;
        })
        .filter((column) => !!column);

      return {
        id: groupDef?.id,
        title: groupDef?.title,
        columns: filteredColumns as GalaxyColumnDef[],
      };
    });
  }

  private addUngroupedFieldsToOtherSection(keyedColumns: { [id: string]: GalaxyColumnDef }, groups: GalaxyColumnDef[]) {
    const groupedFieldIds = groups
      .map((g) => g?.columns || [])
      .flat()
      .map((c: GalaxyColumnDef) => c?.id);
    const ungroupedFields = [];
    for (const [id, column] of Object.entries(keyedColumns)) {
      if (!groupedFieldIds.includes(id) && id !== 'select' && id !== 'actions') {
        ungroupedFields.push(column);
      }
    }
    if (ungroupedFields.length > 0) {
      groups.push({
        id: 'ungrouped-fields',
        title: 'Other',
        columns: ungroupedFields as GalaxyColumnDef[],
      });
    }
    return groups;
  }

  getSearchedGroups$(): Observable<GalaxyColumnDef[]> {
    return combineLatest([this.searchTerm$, this.columnDefsByID$, this.columnDefs$]).pipe(
      map(([searchTerm, keyedColumns]) => {
        const groupDefs: GalaxyColumnDef[] = this.groupDefs$$.getValue();
        const groups: GalaxyColumnDef[] = this.organizeColumnsByGroup(
          keyedColumns as { [id: string]: GalaxyColumnDef },
          groupDefs,
        );
        this.addUngroupedFieldsToOtherSection(keyedColumns as { [id: string]: GalaxyColumnDef }, groups);

        return this.filterGroupsBySearchTerm(groups, searchTerm);
      }),
    );
  }

  updateSearchTerm(st: string): void {
    this.searchTerm$$.next(st);
  }

  filterGroupsBySearchTerm(groups: GalaxyColumnDef[], searchTerm: string): GalaxyColumnDef[] {
    const columnMatchesSearchTerm = (column: GalaxyColumnDef, st: string) =>
      column.title?.toLowerCase().indexOf(st.toLowerCase()) !== -1;
    if (!searchTerm) {
      return groups;
    }
    return groups
      .filter((group) => {
        return group.columns?.some((column) => columnMatchesSearchTerm(column, searchTerm));
      })
      .map(
        (group: GalaxyColumnDef): GalaxyColumnDef => ({
          ...group,
          columns: group.columns?.filter((column) => columnMatchesSearchTerm(column, searchTerm)),
        }),
      );
  }

  setColumnVisibility(id: string, isVisible: boolean): void {
    const cols = this.selectedOrderedColumns$$.value;
    const index = cols.indexOf(id);
    if (isVisible) {
      if (index === -1) {
        cols.push(id);
      }
    } else {
      if (index !== -1) {
        cols.splice(index, 1);
      }
    }
    this.selectedOrderedColumns$$.next(cols);
  }

  selectAll(columnGroup: GalaxyColumnDef): void {
    if (!columnGroup.columns) {
      return;
    }
    columnGroup.columns
      .filter((column) => !column.pinned)
      .map((column) => {
        this.setColumnVisibility(column.id, true);
      });
  }

  deselectAll(columnGroup: GalaxyColumnDef): void {
    if (!columnGroup.columns) {
      return;
    }
    columnGroup.columns
      .filter((column) => !column.pinned)
      .map((column) => {
        this.setColumnVisibility(column.id, false);
      });
  }

  columnDroppedInList(prevIndex: number, curIndex: number): void {
    const columns: string[] = this.selectedOrderedColumns$$.getValue();
    moveItemInArray(columns, prevIndex, curIndex);
    this.selectedOrderedColumns$$.next(columns);
  }

  getSelectedColumns(): string[] {
    return this.selectedOrderedColumns$$.value;
  }
}
