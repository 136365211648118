import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AccessTokenComponent, IDialogResult } from '../access-token/access-token.component';

@Component({
  selector: 'app-primary-navigation',
  templateUrl: './primary-navigation.component.html',
  styleUrls: ['./primary-navigation.component.scss'],
})
export class PrimaryNavigationComponent {
  @Input() secondaryNavigation!: MatSidenav;

  constructor(public dialog: MatDialog, public router: Router) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(AccessTokenComponent);
    dialogRef.afterClosed().subscribe((result: IDialogResult) => {
      if (result) {
        window.location.href = result.url;
      }
    });
  }

  toggle(): void {
    this.secondaryNavigation.toggle();
  }
}
