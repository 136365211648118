import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[glxyGridCard]',
})
export class GalaxyGridCardDirective {
  constructor(
    public templateRef: TemplateRef<any>,
    public viewContainerRef: ViewContainerRef,
  ) {}
}
