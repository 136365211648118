import { Component } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { DateFormat } from '@vendasta/galaxy/utility/date-utils';

@Component({
  template: `
    <div class="value">
      {{ value | glxyDate : format }}
    </div>
  `,
  providers: [
    // for browsers with a negative GMT, the default datepicker would show the previous day
    { provide: DateAdapter, useClass: MomentDateAdapter },
  ],
})
export class TimestampRendererComponent {
  format = DateFormat.medium;
  value: Date = new Date(0);
}
