import { Component } from '@angular/core';
import { ParsedNumber, format, parseNumber } from 'libphonenumber-js';

@Component({
  template: `
    <div class="value" *ngIf="phoneLink; else noPhoneLink">
      <a [href]="phoneLink">
        {{ readonlyPhone }}
      </a>
    </div>
    <ng-template #noPhoneLink>
      <ng-container *ngIf="value">
        <div class="value">{{ value }}</div>
      </ng-container>
      <glxy-blank-value *ngIf="!value"></glxy-blank-value>
    </ng-template>
  `,
  styleUrls: ['./phone.scss'],
})
export class PhoneRendererComponent {
  private _value = '';
  readonlyPhone = '';
  phoneLink = '';

  private setupPhoneValues(): void {
    const parsedNumber = parseNumber(this._value, {
      defaultCountry: 'US',
    });
    if (Object.keys(parsedNumber).length === 0) {
      this.readonlyPhone = '';
      this.phoneLink = '';
      return;
    }

    this.readonlyPhone = format(parsedNumber as ParsedNumber, 'INTERNATIONAL');
    this.phoneLink = format(parsedNumber as ParsedNumber, 'RFC3966');
  }

  get value(): string {
    return this._value;
  }
  set value(v: string) {
    this._value = v;
    this.setupPhoneValues();
  }
}
