import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AdvancedColumnOrganizerComponent } from './advanced-column-organizer.component';
import { GalaxyColumnsSortService } from '../../../services/column-sort.service';
import { GalaxyColumnDef } from '../../../table.interface';

export interface DialogData {
  saveName: string | undefined;
  columnConfigs: GalaxyColumnDef[];
  groupConfigs: GalaxyColumnDef[];
  useI18NTranslations: boolean;
}

@Component({
  selector: 'glxy-mat-table-advanced-column-organizer-button',
  template: '<button mat-stroked-button class="table-btn" (click)="openManager()"><ng-content></ng-content></button>',
})
export class AdvancedColumnOrganizerButtonComponent {
  @Input() saveName?: string;
  @Input() columnConfigs: GalaxyColumnDef[] = [];
  @Input() groupConfigs: GalaxyColumnDef[] = [];
  @Input() useI18NTranslations?: boolean;

  constructor(
    private dialog: MatDialog,
    public colSortService: GalaxyColumnsSortService,
  ) {}

  openManager(): void {
    const dialogData: DialogData = {
      saveName: this.saveName,
      columnConfigs: this.columnConfigs,
      groupConfigs: this.groupConfigs,
      useI18NTranslations: !!this.useI18NTranslations,
    };
    const dialogRef = this.dialog.open(AdvancedColumnOrganizerComponent, {
      width: '800px',
      panelClass: 'glxy-advanced-column-organizer-panel',
      autoFocus: false,
      data: dialogData,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((save) => {
        if (save) {
          this.colSortService.setColumns(dialogRef.componentInstance.columnDefs);
        }
      });
  }
}
