// *********************************
// Code generated by sdkgen
// DO NOT EDIT!.
//
// Module.
// *********************************
import { NgModule } from '@angular/core';
import { HostService } from './_generated/host.service';
import { DeveloperCenterApiService } from './_internal/developer-center.api.service';

@NgModule({
  imports: [],
  providers: [DeveloperCenterApiService, HostService],
  declarations: [],
  exports: [],
})
export class DeveloperCenterModule {}
