import { Component } from '@angular/core';

@Component({
  template: `
    <div class="value" *ngIf="!showBlank()">
      {{ getDisplayValue() }}
    </div>
    <glxy-blank-value *ngIf="showBlank()"></glxy-blank-value>
  `,
})
export class BooleanRendererComponent {
  value: boolean | undefined;

  showBlank(): boolean {
    return !this.value === undefined;
  }

  getDisplayValue(): string {
    if (this.value === undefined) {
      return '-';
    }
    if (this.value) {
      return 'True';
    }
    return 'False';
  }
}
