<mat-checkbox
  class="galaxy-checkbox"
  [id]="id"
  [name]="id"
  [checked]="inputValue"
  [formControl]="formControl"
  (change)="writeValue($event.checked)"
>
  @if (!!label) {
    {{ label }}
  } @else {
    <ng-content></ng-content>
  }
</mat-checkbox>
