import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { PageComponent } from './page.component';
export { PageComponent } from './page.component';

import { PageToolbarComponent } from './page-toolbar/page-toolbar.component';
export { PageToolbarComponent } from './page-toolbar/page-toolbar.component';

import { PageNavButtonComponent } from './page-nav-button/page-nav-button.component';
export { PageNavButtonComponent } from './page-nav-button/page-nav-button.component';

import { PageTitleDirective } from './directives/page-title.directive';
export { PageTitleDirective } from './directives/page-title.directive';

import { PageNavDirective } from './directives/page-nav.directive';
export { PageNavDirective } from './directives/page-nav.directive';

import { PageActionsDirective } from './directives/page-actions.directive';
export { PageActionsDirective } from './directives/page-actions.directive';

import { PageTitleActionsDirective } from './directives/page-title-actions.directive';
export { PageTitleActionsDirective } from './directives/page-title-actions.directive';

import { PageWrapperDirective } from './directives/page-wrapper.directive';
export { PageWrapperDirective } from './directives/page-wrapper.directive';

import { PageExtendedToolbarDirective } from './directives/page-extended-toolbar.directive';
export { PageExtendedToolbarDirective } from './directives/page-extended-toolbar.directive';

import { TranslateModule } from '@ngx-translate/core';

export const MODULE_IMPORTS = [
  CommonModule,
  RouterModule,
  MatIconModule,
  MatTooltipModule,
  MatMenuModule,
  MatButtonModule,
  MatProgressBarModule,
  TranslateModule,
];

export const MODULE_DECLARATIONS = [
  PageComponent,
  PageToolbarComponent,
  PageTitleDirective,
  PageNavDirective,
  PageNavButtonComponent,
  PageActionsDirective,
  PageTitleActionsDirective,
  PageWrapperDirective,
  PageExtendedToolbarDirective,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  exports: [
    PageComponent,
    PageToolbarComponent,
    PageTitleDirective,
    PageNavDirective,
    PageNavButtonComponent,
    PageActionsDirective,
    PageTitleActionsDirective,
    PageWrapperDirective,
    PageExtendedToolbarDirective,
  ],
  imports: MODULE_IMPORTS,
})
export class GalaxyPageModule {}
