import { SelectionModel } from '@angular/cdk/collections';
import { computed, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Row } from '../components/model-driven-cell/interface';
import { GalaxySelectionComponent } from '../components/selection/selection.component';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

interface SelectionEntry {
  select: GalaxySelectionComponent;
  valueSub: Subscription | null;
}

export type SelectionMode = 'single' | 'multiple';

@Injectable()
export class GalaxyTableSelectionService {
  entries: SelectionEntry[] = [];

  private selectionMode$$ = new BehaviorSubject<SelectionMode>('multiple');

  multiSelection = new SelectionModel<Row>(true, [], true, GalaxyTableSelectionService.compareRows);
  singleSelection = new SelectionModel<Row>(false, [], true, GalaxyTableSelectionService.compareRows);

  private selectionModeSig = toSignal(this.selectionMode$$.asObservable());
  selectionModel = computed(() => {
    const mode = this.selectionModeSig();
    if (mode === 'single') {
      return this.singleSelection;
    }
    return this.multiSelection;
  });

  // We only want to emit what is selected, not the diffs
  selection$ = toObservable(this.selectionModel).pipe(
    switchMap((selectionModel) => {
      return selectionModel.changed.pipe(map(() => selectionModel.selected));
    }),
  );

  allSelected$ = toObservable(this.selectionModel).pipe(
    switchMap((selectionModel) => {
      return selectionModel.changed.pipe(
        map(() =>
          this.entries.every((entry) => (entry.select.row ? selectionModel.isSelected(entry.select.row) : false)),
        ),
      );
    }),
  );

  hasSelection$ = toObservable(this.selectionModel).pipe(
    switchMap((selectionModel) => {
      return selectionModel.changed.pipe(map(() => selectionModel.hasValue()));
    }),
  );

  static compareRows(row1: Row, row2: Row): boolean {
    if (row1?.id && row2?.id) {
      return row1.id === row2.id;
    }
    return row1 === row2;
  }

  private removeEntry(select: GalaxySelectionComponent): SelectionEntry {
    const index = this.entries.findIndex((entry) => entry.select === select);
    return this.entries.splice(index, 1)[0];
  }

  /** The "all control" selection has been updated */
  mainChanged(checked: boolean): void {
    if (!checked) {
      this.multiSelection.clear();
      return;
    }
    this.entries.forEach((entry) => {
      if (entry.select.row && !this.isSelected(entry.select.row)) {
        this.multiSelection.select(entry.select.row);
      }
    });
  }

  /**
   * Register a component and its row for selection events
   * @param selectComp - The select component to register and listen to
   */
  registerRow(selectComp: GalaxySelectionComponent): void {
    const entry: SelectionEntry = {
      select: selectComp,
      valueSub: null,
    };

    // register the lord of checkboxes
    if (!selectComp.row) {
      entry.valueSub = selectComp.selected$$.pipe(filter((a) => a !== null)).subscribe({
        next: (checked) => this.mainChanged(!!checked),
      });
    } else {
      entry.valueSub = selectComp.selected$$.pipe(filter((a) => a !== null)).subscribe({
        next: () => {
          if (entry.select.row) this.onSelection(entry.select.row);
        },
      });
      this.entries.push(entry);
    }
  }

  /**
   * Unregister a selection component and its row
   * @param selectComp - The selection component to unregister
   */
  unregisterRow(selectComp: GalaxySelectionComponent): void {
    const entry = this.removeEntry(selectComp);
    if (!entry) {
      return;
    }
    entry.valueSub?.unsubscribe();
  }

  isSelected(row: Row): boolean {
    if (this.selectionMode$$.value === 'single') {
      return this.singleSelection.isSelected(row);
    }
    return this.multiSelection.isSelected(row);
  }

  onSelection(row: Row): void {
    if (this.selectionMode$$.value === 'single') {
      this.singleSelection.toggle(row);
      return;
    }
    this.multiSelection.toggle(row);
  }

  clear() {
    this.singleSelection.clear();
    this.multiSelection.clear();
  }

  setSelectionMode(mode: SelectionMode) {
    this.selectionMode$$.next(mode);
  }

  setSelected(row: Row) {
    if (this.selectionMode$$.value === 'single') {
      this.singleSelection.select(row);
      return;
    }
    this.multiSelection.select(row);
  }
}
