export enum Pinned {
  PINNED_UNSET = 0,
  PINNED_LEFT = 1,
  PINNED_RIGHT = 2,
}

export interface ColumnInfo {
  id: string;
  name: string;
  isVisible: boolean;
  pinned?: Pinned;
}
