import { Injectable } from '@angular/core';
import {
  GalaxyDataSource,
  PagedListRequestInterface,
  PagedResponseInterface,
  Row,
  RowData,
  TagsData,
} from '@vendasta/galaxy/table';
import { GalaxyColumnDef } from '@vendasta/galaxy/table/src/table.interface';
import { BehaviorSubject, Observable, Subscription, interval, of } from 'rxjs';
import { getDecodedAccessToken } from './access-token.service';
import { IAccessToken } from './shared/access-token/access-token.component';

export interface ITokenData {
  // template driven data
  env?: string;
  partner?: string;
  token?: string;
  scopes?: TagsData;
  // model driven data
  issued_time?: string;
  expires_at?: string;
  countDown?: BehaviorSubject<Date>;
}

@Injectable()
export class AccessTokenDataService {
  data = {};
  cursors = [];
  pageSize: number;
  text: string;
  countdowns: { [key: number]: BehaviorSubject<number> } = {};
  timer$: Subscription;
  columns: GalaxyColumnDef[] = [
    // template-driven columns
    {
      id: 'env',
      title: 'Env',
      sticky: true,
    },
    {
      id: 'partner',
      title: 'Partner ID',
      pinned: true,
    },
    {
      id: 'scopes',
      pinned: true,
      title: 'Scopes',
    },
    {
      id: 'issued_time',
      title: 'Issued Time',
    },
    {
      id: 'expires_at',
      title: 'Expires At',
    },
    {
      id: 'actions',
      pinned: true,
      sticky: true,
    },
  ];

  getColumn$(): Observable<GalaxyColumnDef[]> {
    return of(this.columns);
  }
  get paginatedApi() {
    return {
      get: (req: PagedListRequestInterface): Observable<PagedResponseInterface<Row>> => {
        const rowData = this.getRowDataFromLocalStorage();

        const searchFilteredRows = this.getSearchFilteredRows(rowData, req.searchOptions.text);

        const pageRows: Row[] = [];
        const cursorAsNumber: number = req.pagingOptions.cursor ? Number(req.pagingOptions.cursor) : 0;
        const pageSize = req.pagingOptions.pageSize || 0;
        for (
          let rowIndex = cursorAsNumber;
          rowIndex < Math.min(cursorAsNumber + pageSize, searchFilteredRows.length);
          rowIndex++
        ) {
          const rowData: RowData = {};
          for (let columnIndex = 0; columnIndex < this.columns.length; columnIndex++) {
            if (['env', 'partner', 'issued_time', 'expires_at', 'countDown'].includes(this.columns[columnIndex].id)) {
              continue;
            }

            rowData[this.columns[columnIndex].id] = searchFilteredRows[rowIndex][this.columns[columnIndex].id];
          }

          const row = {
            id: `ROW-${rowIndex}`,
            // model driven data must be in the `data` property
            data: rowData,
            // template driven data must be at the top level
            env: searchFilteredRows[rowIndex].env,
            partner: searchFilteredRows[rowIndex].partner,
            token: searchFilteredRows[rowIndex].token,
            issued_time: searchFilteredRows[rowIndex].issued_time,
            expires_at: searchFilteredRows[rowIndex].expires_at,
            countDown: searchFilteredRows[rowIndex].countDown,
          };

          pageRows.push(row);
        }

        return of({
          data: pageRows,
          pagingMetadata: {
            hasMore: cursorAsNumber + pageSize < searchFilteredRows.length,
            nextCursor: (cursorAsNumber + pageSize).toString(),
          },
        });
      },
    };
  }
  getDataSource(): GalaxyDataSource<Row> {
    return new GalaxyDataSource<Row>(this.paginatedApi);
  }

  getSearchFilteredRows(rows: ITokenData[], searchText: string): ITokenData[] {
    return rows.filter((row) => {
      if (!searchText) {
        return true;
      }

      searchText = searchText.toLowerCase();

      return Object.keys(row).some((key) => {
        const prop = row[key];
        if (typeof prop === 'object' && 'value' in prop && 'cellType' in prop) {
          return prop['value'].toString().toLowerCase().includes(searchText);
        }
        return prop?.toString().toLowerCase().includes(searchText);
      });
    });
  }

  private tagsData(value?: string[]): TagsData {
    return {
      cellType: 'tags',
      value: value,
    };
  }

  getRowsFromInput(tokens: IAccessToken[]): ITokenData[] {
    const rows = [];
    tokens.forEach((token) => {
      const { iat, exp } = this.decodeToken(token.accessToken);
      const row = {
        env: token.environment,
        partner: token.partner,
        token: token.accessToken,
        scopes: this.tagsData(token.scopes.split(' ')),
        issued_time: iat,
        expires_at: exp,
      };
      const startTime = new Date(exp);
      const timeRemaining$ = new BehaviorSubject(Math.max(0, Math.floor((startTime.getTime() - Date.now()) / 1000)));
      row['countDown'] = timeRemaining$;
      rows.push(row);
    });
    rows.sort((a, b) => {
      return b.expires_at - a.expires_at;
    });

    //Initiate timer to countdown the token expiry
    this.timer$ = interval(1000).subscribe(() => {
      rows.forEach((timeRemaining$) => {
        const startTime = new Date(timeRemaining$.expires_at * 1000);
        timeRemaining$.countDown.next(Math.max(0, Math.floor((startTime.getTime() - Date.now()) / 1000)));
      });
    });
    return rows;
  }

  private getRowDataFromLocalStorage(): ITokenData[] {
    const rows = [];
    const tokens = JSON.parse(localStorage.getItem('vendasta_tokens'));
    if (!tokens) return rows;
    return this.getRowsFromInput(tokens);
  }

  private decodeToken(token): any {
    const tokenVars = getDecodedAccessToken(token);
    return {
      scopes: tokenVars['scope'].split(' '),
      iat: tokenVars['iat'],
      exp: tokenVars['exp'],
    };
  }

  ngOnDestroy() {
    this.timer$.unsubscribe();
  }
}
