import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule, Optional, importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LexiconModule } from '@galaxy/lexicon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GalaxyPageModule } from '@vendasta/galaxy/page';
import { GalaxyDefaultProviderOverrides } from '@vendasta/galaxy/provider-default-overrides';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MARKED_OPTIONS, MarkdownModule, MarkdownModuleConfig } from 'ngx-markdown';
import { Observable, of, map, shareReplay, startWith } from 'rxjs';
import En from '../assets/i18n/en.json';
import { AccessTokenDataService } from './access-token-service.service';
import { AccessTokenService } from './access-token.service';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { HomeComponent } from './home/home.component';
import { markedOptionsFactory } from './markdown';
import { SharedModule } from './shared/shared.module';
@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    routing,
    SharedModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
    } as MarkdownModuleConfig),
    MatListModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatSnackBarModule,
    MatCardModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    GalaxyPageModule,
    TranslateModule,
    LexiconModule.forRoot({
      componentName: 'common/observatory',
      baseTranslation: En,
    }),
  ],
  providers: [
    ...GalaxyDefaultProviderOverrides,
    AccessTokenService,
    { provide: APP_BASE_HREF, useValue: '/' },
    importProvidersFrom(HttpClientModule),
    // provideOAuthClient(),

    AccessTokenDataService,
    {
      provide: 'CURRENT_LANG',
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => {
        return translateService.onLangChange.pipe(
          map((langChange) => langChange.lang),
          startWith(translateService.currentLang),
        );
      },
    },
    {
      provide: 'DEFAULT_LANG',
      useFactory: () => of('en').pipe(shareReplay({refCount: true, bufferSize: 1})),
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(
    private translateService: TranslateService,
    @Optional() @Inject('CURRENT_LANG') currentLang$: Observable<string>,
    @Optional() @Inject('DEFAULT_LANG') defaultLang$: Observable<string>,
  ) {
    if (currentLang$) {
      currentLang$.subscribe((currentLang) => {
        return translateService.use(currentLang);
      });
    }
    if (defaultLang$) {
      defaultLang$.subscribe((defaultLang) => translateService.setDefaultLang(defaultLang));
    }
  }
}
