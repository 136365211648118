<glxy-table-container
  class="comprehensive-table"
  [dataSource]="dataSource"
  [columns]="columns$ | async"
  [showSelection]="false"
  [pageSizeOptions]="[5]"
  [pageSize]="5"
  [border]="true"
  [fullWidth]="false"
>
  <table mat-table>
    <tr mat-header-row *matHeaderRowDef="[]"></tr>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <glxy-table-selection></glxy-table-selection>
      </th>
      <td mat-cell class="mat-cell-reformat" *matCellDef="let row">
        <glxy-table-selection class="mat-cell-reformat" [row]="row"></glxy-table-selection>
      </td>
    </ng-container>
    <ng-container matColumnDef="env">
      <th mat-header-cell *matHeaderCellDef>Environment</th>
      <td mat-cell class="mat-cell-reformat" *matCellDef="let element">{{ element.env }}</td>
    </ng-container>
    <ng-container matColumnDef="expires_at">
      <th mat-header-cell *matHeaderCellDef>Expiration</th>

      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.countDown | async; else expired">
          {{ formatTime(element.countDown | async) }}
        </ng-container>
        <ng-template #expired>
          <del>expired</del>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="partner">
      <th mat-header-cell *matHeaderCellDef>Partner</th>
      <td mat-cell class="mat-cell-reformat" *matCellDef="let element">{{ element.partner }}</td>
    </ng-container>
    <ng-container matColumnDef="issued_time">
      <th mat-header-cell *matHeaderCellDef>Issued At</th>
      <td mat-cell class="mat-cell-reformat" *matCellDef="let element">
        {{ element.issued_time * 1000 | date : 'short' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell class="narrow-cell" *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let row" class="narrow-cell">
        <div class="flex-row">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <div matTooltipPosition="right" matTooltip="Copy to clipboard">
              <button mat-menu-item (click)="copy(row)">
                <span>Copy</span>
              </button>
            </div>
            <div matTooltipPosition="right" matTooltip="Refresh Token">
              <button mat-menu-item (click)="refresh(row)">
                <span>Refresh</span>
              </button>
            </div>
            <div matTooltipPosition="right" matTooltip="Set token on site">
              <button mat-menu-item (click)="setToken(row)">
                <span>Set Token</span>
              </button>
            </div>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <tr
      mat-row
      class="mat-cell-reformat"
      *matRowDef="let row; columns: []"
      [ngClass]="{
        highlight:
          (getStoplightToken.OAuth2Prod || getStoplightToken.OAuth2Demo) &&
          (row.token === getStoplightToken.OAuth2Prod || row.token === getStoplightToken.OAuth2Demo)
      }"
    ></tr>
  </table>
</glxy-table-container>
