<div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListSortPredicate]="sortAgainstPinned">
  <ng-container *ngFor="let col of columnsFlat">
    <div *ngIf="col.id !== 'select'" class="entry" cdkDrag [cdkDragDisabled]="col.pinned" cdkDragLockAxis="y">
      <mat-icon cdkDragHandle>
        {{ col.pinned ? 'push_pin' : 'drag_indicator' }}
      </mat-icon>
      <glxy-checkbox *ngIf="this.formGroup" [disabled]="!!col.pinned" [formControl]="this.formGroup.controls[col.id]">
        {{ col.title || col.id | translate }}
      </glxy-checkbox>
    </div>
  </ng-container>
</div>
