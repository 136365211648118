import { Component, DestroyRef, HostBinding, inject, Input } from '@angular/core';
import { GalaxyDataSource } from '../../../../table';
import { combineLatest, Subject } from 'rxjs';
import { GalaxyGridCardDirective } from './grid-card.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'glxy-table-grid-view, [glxy-table-grid-view]',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss'],
})
export class GridViewComponent<T> {
  @HostBinding('class') class = 'glxy-table-grid-view';
  @HostBinding('style.grid-template-columns') gridTemplateColumns =
    `repeat(auto-fill, minmax(${this.minWidth}px, 1fr))`;
  @Input()
  set minWidth(width: number) {
    this.gridTemplateColumns = 'repeat(auto-fill, minmax(' + width + 'px, 1fr))';
  }

  private destroyRef: DestroyRef = inject(DestroyRef);
  private _datasource?: GalaxyDataSource<T, unknown, unknown>;
  set dataSource(dataSource: GalaxyDataSource<T, unknown, unknown>) {
    if (dataSource) {
      this.switchDataSource(dataSource);
    }
  }

  private data$$ = new Subject<T[]>();
  private cardDirective$$ = new Subject<GalaxyGridCardDirective>();

  @Input()
  set cardDirective(directive: GalaxyGridCardDirective) {
    if (directive) {
      this.cardDirective$$.next(directive);
    }
  }

  constructor() {
    combineLatest([this.data$$.asObservable(), this.cardDirective$$.asObservable()])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([data, directive]) => {
        directive.viewContainerRef.clear();
        for (const row of data) {
          const context = {
            $implicit: row,
          };
          directive.viewContainerRef.createEmbeddedView(directive.templateRef, context);
        }
      });
  }

  private switchDataSource(dataSource: GalaxyDataSource<T, unknown, unknown>) {
    this._datasource = dataSource;
    this._datasource.dataMembers$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: T[]) => {
      this.data$$.next(data);
    });
  }
}
