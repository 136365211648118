import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { GalaxyColumnsSortService } from '../../../services/column-sort.service';
import { GalaxyColumnDef } from '../../../table.interface';

@Component({
  selector: 'glxy-column-sort-simple',
  templateUrl: './column-sort-simple.component.html',
  styleUrls: ['./column-sort-simple.component.scss'],
})
export class ColumnSortSimpleComponent implements OnDestroy, OnChanges {
  /** List of colums to be controlled by this component */
  @Input() columns: GalaxyColumnDef[] = [];

  // Flattened columns to be show in UI
  columnsFlat: GalaxyColumnDef[] = [];

  // Used to listen for column visibility changes
  formGroup?: UntypedFormGroup;

  // List of subscriptions created for UI checkboxes
  controlSubs: Subscription[] = [];

  constructor(public colSortService: GalaxyColumnsSortService) {}

  ngOnDestroy(): void {
    this.controlSubs.forEach((sub) => sub.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns.firstChange || changes.columns?.currentValue !== changes.columns?.previousValue) {
      // unsubscribe from all the old form controls
      this.controlSubs.forEach((sub) => sub.unsubscribe());

      this.flattenColumns();

      const controls: { [key: string]: AbstractControl } = {};

      this.columnsFlat.forEach((col: GalaxyColumnDef) => {
        controls[col.id] = new UntypedFormControl(!col.hidden);
        if (col.pinned) {
          controls[col.id].disable();
        }
        const sub = controls[col.id].valueChanges
          .pipe(distinctUntilChanged())
          .subscribe((visible) => this.updateColHidden(col, !visible));
        this.controlSubs.push(sub);
      });

      this.formGroup = new UntypedFormGroup(controls);
    }
  }

  /**
   * Flattens the list of columns for use in UI.
   * Note: we might want to pull this into a utility method
   */
  flattenColumns(): void {
    this.columnsFlat = [];
    this.columns?.forEach((col: GalaxyColumnDef) => {
      if (col.columns) {
        col.columns.forEach((subCol: GalaxyColumnDef) => {
          this.columnsFlat.push(subCol);
        });
      } else {
        this.columnsFlat.push(col);
      }
    });
  }

  /**
   * NOTE THAT THIS EVENT STRIPS GROUPS. We don't care about groups in simple column arrangement
   * @param event - CDK Drop event provided when we drop an element in the list, in a new position
   */
  drop(event: CdkDragDrop<string[]>): void {
    // Adjust for select column being hidden
    const indexAdjust = this.columnsFlat[0].id === 'select' ? 1 : 0;

    moveItemInArray(this.columnsFlat, event.previousIndex + indexAdjust, event.currentIndex + indexAdjust);

    this.columnsFlat.forEach((col: GalaxyColumnDef, i: number) => {
      col._position = i;
    });

    this.colSortService.setColumns(this.columnsFlat);
  }

  /**
   * Confirm that we can allow the columns to be sorted when this item is droped.
   * @param index - Index of the item to work into the list
   * @returns True if the target location is not a pinned spot, and we can allow the drop
   */
  sortAgainstPinned = (index: number): boolean => {
    // Adjust for select column being hidden
    const indexAdjust = this.columnsFlat[0].id === 'select' ? 1 : 0;

    const target = this.columnsFlat.find((col: GalaxyColumnDef) => col._position === index + indexAdjust);
    return !target?.pinned;
  };

  /**
   * Update a column's visibility.
   * @param col - The column to updated
   * @param hidden - If true, this column will be hidden
   */
  updateColHidden(col: GalaxyColumnDef, hidden: boolean): void {
    col.hidden = hidden;
    this.colSortService.setColumns(this.columnsFlat);
  }
}
