{
  "GALAXY": {
    "PAGE_NOT_FOUND_404": {
      "TITLE": "Page not found",
      "SUBTITLE": "Please verify the address and try again."
    },
    "PAGE_NO_ACCESS": {
      "TITLE": "No access",
      "SUBTITLE": "You are not authorized to view this page."
    },
    "CONFIRMATION_MODAL": {
      "CONFIRM": "Confirm",
      "CANCEL": "Cancel",
      "DONT_SHOW_AGAIN": "Don't show again"
    },
    "AI_TEXT_BUTTON": {
      "SUGGEST": "Suggest",
      "CREATE_FROM_CURRENT": "Create from current content",
      "SPELLING_AND_GRAMMAR": "Fix spelling & grammar",
      "CHANE_TONE": "Change tone",
      "TONE": {
        "FRIENDLY": "Friendly",
        "LUXURIOUS": "Luxurious",
        "PROFESSIONAL": "Professional",
        "RELAXED": "Relaxed",
        "BOLD": "Bold",
        "ADVENTUROUS": "Adventurous",
        "WITTY": "Witty",
        "PERSUASIVE": "Persuasive",
        "EMPATHETIC": "Empathetic"
      }
    },
    "INPUT": {
      "CORE": {
        "VALIDATION_ERROR_REQ": "This is required.",
        "REQUIRED": "Required"
      },
      "EMAIL": {
        "LABEL": "Email",
        "VALIDATION_ERROR": "Invalid Email Address."
      },
      "PASSWORD": {
        "VALIDATION_ERROR_NO_MATCH": "Passwords do not match.",
        "STRENGTH_STRONG": "strong",
        "STRENGTH_MEDIUM": "medium",
        "STRENGTH_WEAK": "weak",
        "STRENGTH_NONE": "none",
        "CONFIRM": "Confirm",
        "PASS_STRENGTH": "Password Strength"
      },
      "PHONE": {
        "LABEL": "Phone number",
        "VALIDATION_ERROR": "Invalid phone number entered."
      }
    },
    "SNACKBAR": {
      "ACTION_BUTTON": {
        "DISMISS": "Dismiss"
      },
      "ERROR_MESSAGE": "Something went wrong. Refreshing the page might help, but let us know if this keeps happening."
    },
    "CHAT": {
      "YOU": "You",
      "MESSAGE_STATUS": {
        "SENDING": "Sending",
        "SENT": "Sent",
        "FAILED": "Failed",
        "DELIVERED": "Delivered",
        "UNDELIVERED": "Not delivered"
      }
    },
    "DATEPICKER": {
      "SELECT_DATE": "Select a date",
      "INVALID_DATE": "Invalid date",
      "MAX_DATE_ERROR": "Cannot select a date in the future"
    },
    "DATE_RANGE": {
      "SELECT_DATE_RANGE": "Select a date range",
      "NO_PERIOD": "No date range selected",
      "SELECTED_DATE_RANGE": "Selected date range",
      "COMPARE_TO": "Compare to",
      "CANCEL": "Cancel",
      "ERROR_MESSAGE": "Invalid date range",
      "APPLY": "Apply",
      "MONTHS": {
        "MONTH_0": "Jan",
        "MONTH_1": "Feb",
        "MONTH_2": "Mar",
        "MONTH_3": "Apr",
        "MONTH_4": "May",
        "MONTH_5": "Jun",
        "MONTH_6": "Jul",
        "MONTH_7": "Aug",
        "MONTH_8": "Sep",
        "MONTH_9": "Oct",
        "MONTH_10": "Nov",
        "MONTH_11": "Dec"
      },
      "PERIOD_SELECTOR": {
        "CUSTOM": "Custom",
        "LAST_7_DAYS": "Last 7 days",
        "LAST_30_DAYS": "Last 30 days",
        "LAST_90_DAYS": "Last 90 days",
        "LAST_6_MONTHS": "Last 6 months",
        "LAST_12_MONTHS": "Last 12 months",
        "BY_MONTH": "Month",
        "BY_QUARTER": "Quarter"
      },
      "INVALID_START_DATE": "Invalid start date",
      "INVALID_END_DATE": "Invalid end date"
    },
    "UPLOADER": {
      "CONSTRAINTS": {
        "MAX_FILES_PREFIX": "Up to",
        "IMAGE_TYPE": "image",
        "IMAGE_TYPE_PLURAL": "images",
        "VIDEO_TYPE": "video",
        "AUDIO_TYPE": "audio",
        "FILES": "files",
        "MAX_FILE_SIZE": "max file size",
        "AND": "and"
      },
      "ERROR": {
        "MAX_FILE_SIZE_EXCEEDED": "Max file size exceeded. Please use a different file",
        "MAX_FILES_EXCEEDED": "Max files exceeded",
        "MAX_DIMENSIONS_EXCEEDED": "Image dimensions are larger than allowed",
        "NO_VALID_FILE_IMAGE": "No valid image file to upload",
        "DUPLICATE_FILE_ATTEMPT": "This file has already been uploaded",
        "GENERIC": "An error occurred, please try again",
        "FILE_TYPE_NOT_SUPPORTED": "File type not supported"
      },
      "DRAG_AND_DROP": "Drag and drop a file here",
      "DRAG_AND_DROP_PL": "Drag and drop files here",
      "CHOOSE_FILE": "Choose file",
      "CHOOSE_FILE_PL": "Choose files",
      "DRAG_AND_DROP_IMAGE": "Drag and drop an image here",
      "DRAG_AND_DROP_IMAGE_PL": "Drag and drop images here",
      "CHOOSE_IMAGE": "Choose image",
      "CHOOSE_IMAGE_PL": "Choose images",
      "SELECT_ASPECT": "Aspect ratio",
      "FREE_ASPECT": "Free",
      "CANCEL": "Cancel",
      "SAVE": "Save",
      "NO_IMAGE": "No image selected"
    },
    "TIMEZONE": {
      "LABEL": "Timezone"
    },
    "FILTER": {
      "CHIP": {
        "DATE_DEFAULT": {
          "INVALID": "Exact date",
          "TODAY": "Today",
          "YESTERDAY": "Yesterday",
          "TOMORROW": "Tomorrow",
          "THIS_WEEK": "This week",
          "LAST_WEEK": "Last week",
          "NEXT_WEEK": "Next week",
          "THIS_MONTH": "This month",
          "LAST_MONTH": "Last month",
          "NEXT_MONTH": "Next month",
          "THIS_QUARTER": "This quarter",
          "LAST_QUARTER": "Last quarter",
          "NEXT_QUARTER": "Next quarter",
          "THIS_YEAR": "This year",
          "LAST_YEAR": "Last year",
          "NEXT_YEAR": "Next year"
        },
        "ACTIONS.APPLY": "Apply",
        "TOGGLE_BUTTON": "Filters",
        "APPLIED_INDICATOR": "Filters applied",
        "ADD_FILTER": "Add filter",
        "CLEAR_FILTERS": "Clear all",
        "FIND_FILTER": "Find filter",
        "INVALID_FILTER_TYPE": "Filter type is invalid",
        "INCOMPLETE_FILTER": "Filter is incomplete. Complete the filter before applying it.",
        "SELECT_OPERATOR_PLACEHOLDER": "Select operator...",
        "SELECT_EXPRESSION_PLACEHOLDER": "Select...",
        "SELECT_START_PLACEHOLDER": "Start date",
        "SELECT_END_PLACEHOLDER": "End date",
        "OPERATOR": {
          "INVALID": "invalid",
          "IS_EMPTY": "✕ is empty",
          "IS_NOT_EMPTY": "✓ is not empty",
          "IS": "= is",
          "IS_NOT": "≠ is not",
          "IS_ANY": "∋ is any of",
          "IS_NOT_ANY": "∌ is not any of",
          "IS_ALL": "= is all of",
          "IS_NOT_ALL": "≠ is not all of",
          "CONTAINS": "∋ contains",
          "DOES_NOT_CONTAIN": "∌ does not contain",
          "IS_EQUAL_TO": "= is equal to",
          "IS_NOT_EQUAL_TO": "≠ is not equal to",
          "IS_GREATER_THAN": "> is greater than",
          "IS_GREATER_THAN_OR_EQUAL_TO": "≥ is greater than or equal to",
          "IS_LESS_THAN": "< is less than",
          "IS_LESS_THAN_OR_EQUAL_TO": "≤ is less than or equal to",
          "IS_BEFORE": "< is before",
          "IS_BEFORE_OR_ON": "≤ is before or on",
          "IS_AFTER": "> is after",
          "IS_AFTER_OR_ON": "≥ is after or on",
          "IS_BETWEEN": "= is between",
          "IS_NOT_BETWEEN": "≠ is not between"
        },
        "SYMBOL": {
          "INVALID": "is INVALID",
          "IS_EMPTY": "✕",
          "IS_NOT_EMPTY": "✓",
          "IS": "=",
          "IS_NOT": "≠",
          "IS_ANY": "∋",
          "IS_NOT_ANY": "∌",
          "IS_ALL": "=",
          "IS_NOT_ALL": "≠",
          "CONTAINS": "∋",
          "DOES_NOT_CONTAIN": "∌",
          "IS_EQUAL_TO": "=",
          "IS_NOT_EQUAL_TO": "≠",
          "IS_GREATER_THAN": ">",
          "IS_GREATER_THAN_OR_EQUAL_TO": "≥",
          "IS_LESS_THAN": "<",
          "IS_LESS_THAN_OR_EQUAL_TO": "≤",
          "IS_BEFORE": "<",
          "IS_BEFORE_OR_ON": "≤",
          "IS_AFTER": ">",
          "IS_AFTER_OR_ON": "≥",
          "IS_BETWEEN": "=",
          "IS_NOT_BETWEEN": "≠"
        },
        "VALUE_PLACEHOLDER": "Value...",
        "START_VALUE_PLACEHOLDER": "Start value",
        "END_VALUE_PLACEHOLDER": "End value"
      }
    },
    "FREQUENCY": {
      "SHORT": {
        "DAILY": "/da",
        "WEEKLY": "/wk",
        "BI_WEEKLY": "/bw",
        "MONTHLY": "/mo",
        "QUARTERLY": "/qtr",
        "YEARLY": "/yr"
      },
      "LONG": {
        "DAILY": "/day",
        "WEEKLY": "/week",
        "BI_WEEKLY": "/two weeks",
        "MONTHLY": "/month",
        "QUARTERLY": "/quarter",
        "YEARLY": "/year"
      },
      "VERBOSE": {
        "DAILY": "per day",
        "WEEKLY": "per week",
        "BI_WEEKLY": "every two weeks",
        "MONTHLY": "per month",
        "QUARTERLY": "per quarter",
        "YEARLY": "per year"
      }
    },
    "PAGINATION": {
      "INDETERMINATE_AMOUNT": "many",
      "OF": "of",
      "ITEMS_PER_PAGE": "Items per page",
      "NEXT_PAGE": "Next Page",
      "PREVIOUS_PAGE": "Previous Page"
    },
    "PAGE": {
      "TOGGLE_MENU": "Toggle menu"
    },
    "PAGE_NAV": {
      "TOGGLE_LABEL": "Contents"
    },
    "TABLE": {
      "FILTERS": {
        "TOGGLE_BUTTON": "Filters",
        "APPLIED_INDICATOR": "Filters applied"
      }
    },
    "TAGS": {
      "PLACEHOLDER": "Add a tag...",
      "STRING_LIMIT_HINT": "Maximum {{maxLength}} characters"
    }
  }
}
