import { ChangeDetectionStrategy, Component, DestroyRef, Input, inject } from '@angular/core';
import { PopoverPositions } from '@vendasta/galaxy/popover';
import { GalaxyColumnsSortService } from '../../services/column-sort.service';
import { GalaxyColumnDef } from '../../table.interface';
import { startWith, Observable } from 'rxjs';

@Component({
  selector: 'glxy-column-sort',
  templateUrl: './column-sort.component.html',
  styleUrls: ['./column-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalaxyColumnSortComponent {
  /** Which type of UI to display  */
  @Input() type: 'simple' | 'advanced' = 'simple';

  /** List of columns to rearrange */
  columns$: Observable<GalaxyColumnDef[]> = this.colSortService.columns$$.asObservable().pipe(startWith([]));

  groupConfigs$: Observable<GalaxyColumnDef[]> = this.colSortService.groupConfigs$$?.asObservable().pipe(startWith([]));

  /** Whether or not the UI is open */
  isOpen = false;

  /** Mapping of positions for use in template */
  PopoverPositions = PopoverPositions;

  private readonly destroyRef = inject(DestroyRef);

  constructor(public colSortService: GalaxyColumnsSortService) {}
}
