import { Injectable, QueryList } from '@angular/core';
import { MatColumnDef } from '@angular/material/table';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GalaxyColumnDef } from '../table.interface';

/**
 * Service for managing which columns are displayed, and which aren't
 * A single "table view service" should manage saving this off to local storage, query
 * params, and any other storage option
 */
@Injectable()
export class GalaxyColumnsSortService {
  // List of columns manged by this service
  columns?: GalaxyColumnDef[];

  // Query List of column defs we can manipulate
  columnDefs?: QueryList<MatColumnDef> | MatColumnDef[];

  /**
   * Replay subject containing the list of columns that are managed by this service.
   */
  columns$$: ReplaySubject<GalaxyColumnDef[]> = new ReplaySubject<GalaxyColumnDef[]>(1);

  groupConfigs$$: ReplaySubject<GalaxyColumnDef[]> = new ReplaySubject<GalaxyColumnDef[]>(1);

  /**
   * Observable containing a list of IDs of columns that are visible.
   */
  visibleColumns$: Observable<string[]> = this.columns$$.pipe(
    map((cols: GalaxyColumnDef[]) => {
      const flatColumns: GalaxyColumnDef[] = [];

      cols?.forEach((col) => {
        if (col.columns) {
          col.columns.forEach((subCol) => {
            flatColumns.push(subCol);
          });
        } else if (!col.hidden) {
          flatColumns.push(col);
        }
      });

      return (
        flatColumns
          // Sort by position
          .sort((colA, colB) => {
            return colA._position !== undefined && colB._position != undefined ? colA._position - colB._position : 0;
          })
          // Filter out hidden ones
          .filter((col) => !col.hidden)
          // Just the IDs
          .map((col) => col.id)
      );
    }),
  );

  /**
   * Update the list of columns
   * @param columns - List of columns to override the current with
   */
  setColumns(columns: GalaxyColumnDef[]): void {
    this.columns = columns;

    // If columns do not have positions applied, apply them now for ordering
    let position = 0;
    this.columns?.forEach((col: GalaxyColumnDef) => {
      if (col.columns) {
        col.columns.forEach((sub: GalaxyColumnDef) => {
          sub._position = position;
          position++;
        });
      } else {
        col._position = position;
        position++;
      }
    });

    this.updatePinnableColumns();
    if (this.columnDefs) {
      this.updateStickyColumns();
    }

    this.columns$$.next(columns);
  }

  setColumnDefs(columnDefs: QueryList<MatColumnDef> | MatColumnDef[]): void {
    this.columnDefs = columnDefs;

    this.updateStickyColumns();
  }

  setGroups(groups: GalaxyColumnDef[]): void {
    this.groupConfigs$$.next(groups);
  }

  /**
   * Updates the list of columns to make sure they are allowed to be pinnable
   * Columns at the start or end, and touching a pinnable column, are allowed to be pinned
   */
  private updatePinnableColumns(): void {
    const flattened = this.flattenCols();

    const sorted = flattened.sort((a, b) => {
      return a._position && b._position ? a._position - b._position : 0;
    });

    // We don't care about first entry
    let startAnchor = 1;
    let anchoredToStart = true;
    // We don't care about last entry
    let endAnchor = sorted.length - 2;
    let anchoredToEnd = true;

    // Check to see that the pins are anchored to a start pin
    while (startAnchor < endAnchor && anchoredToStart) {
      const col = sorted[startAnchor];

      if (col.pinned) {
        const previousCol = sorted[startAnchor - 1];
        if (!previousCol.pinned) {
          col.pinned = false;
        }
      }

      if (!col.pinned) {
        anchoredToStart = false;
        break;
      }

      startAnchor++;
    }

    // Check against end anchor point, otherwise clear back to the start anchor point
    while (endAnchor > startAnchor) {
      const col = sorted[endAnchor];

      if (col.pinned) {
        const nextCol = sorted[endAnchor + 1];
        if (!nextCol.pinned || !anchoredToEnd) {
          col.pinned = false;
        }
      }

      if (!col.pinned) {
        anchoredToEnd = false;
      }

      endAnchor--;
    }
  }

  /**
   * Update columns to be sticky if the galaxy column def says it is so
   */
  private updateStickyColumns(): void {
    const flattened = this.flattenCols();

    flattened.forEach((col) => {
      const colDef = this.columnDefs?.find((def) => def.name === col.id);

      if (colDef && colDef.sticky !== col.sticky) {
        colDef.sticky = !!col.sticky;
      }

      if (colDef && colDef.stickyEnd !== col.stickyEnd) {
        colDef.stickyEnd = !!col.stickyEnd;
      }
    });
  }

  /**
   * Flatten the list of columns so that we can handle it easily
   * @returns Flattened list of columns
   */
  private flattenCols(): GalaxyColumnDef[] {
    const flattened: GalaxyColumnDef[] = [];
    this.columns?.forEach((col) => {
      if (col.columns) {
        col.columns.forEach((sub) => {
          flattened.push(sub);
        });
      } else {
        flattened.push(col);
      }
    });

    return flattened;
  }
}
