<!-- Begin Modal Content -->
<h2 mat-dialog-title class="mat-mdc-dialog-title">
  Manage columns
  <button
    mat-icon-button
    mat-dialog-close
    class="close-modal"
    *ngIf="isMobileScreen()"
    (click)="onClose()"
    tabindex="-1"
  >
    <mat-icon aria-label="Close">close</mat-icon>
  </button>
</h2>

<mat-dialog-content class="advanced-column-organizer-content" autoFocus="false">
  <!-- Desktop View -->
  <ng-container *ngIf="!isMobileScreen()">
    <div class="desktop-wrapper">
      <div class="desktop-left-pane">
        <ng-container *ngTemplateOutlet="filtered_checkablelist"></ng-container>
      </div>

      <div class="desktop-right-pane">
        <ng-container *ngTemplateOutlet="filtered_dragable_list"></ng-container>
      </div>
    </div>
  </ng-container>

  <!-- Mobile View -->
  <ng-container *ngIf="isMobileScreen()">
    <div class="mobile-wrapper">
      <mat-tab-group
        animationDuration="0ms"
        class="mobile-tabs"
        mat-align-tabs="center"
        [selectedIndex]="activeTab"
        (selectedTabChange)="setActiveTab($event.index.toString())"
      >
        <mat-tab label="Choose columns">
          <div class="mobile-max-width">
            <ng-container *ngTemplateOutlet="filtered_checkablelist"></ng-container>
          </div>
        </mat-tab>

        <mat-tab label="Arrange columns">
          <div class="mobile-max-width">
            <ng-container *ngTemplateOutlet="filtered_dragable_list"></ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close (click)="onClose()">Cancel</button>
  <button mat-flat-button color="primary" mat-dialog-close (click)="save()">Save</button>
</mat-dialog-actions>
<!-- End Modal Content -->

<!-- Available Column List -->
<ng-template #filtered_checkablelist>
  <div class="section-container">
    <div class="section-content">
      <glxy-form-field suffixIcon="search" [showLabel]="false">
        <glxy-label>Search</glxy-label>
        <input type="text" placeholder="Search columns..." matInput [formControl]="searchControl" />
      </glxy-form-field>
      <!-- No Results -->
      <ng-container *ngIf="searchedGroups$ | async as searchedGroups">
        <div class="no-results" *ngIf="searchedGroups.length === 0">
          No columns matched '{{ searchControl.value }}'. Please try another term.
        </div>
      </ng-container>

      <!-- List of checkboxes -->
      <ng-container *ngFor="let columnGroup of searchedGroups$ | async">
        <ng-container *ngIf="columnGroup.columns.length !== 0">
          <div class="column-group">
            <h4 class="column-group-name">
              {{ columnGroup.title }}
              <div class="select-all-controls">
                <a (click)="selectAll(columnGroup)">Select All</a>
                |
                <a (click)="deselectAll(columnGroup)">Deselect All</a>
              </div>
            </h4>
            <ul class="column-group-list">
              <ng-container *ngFor="let column of columnGroup.columns">
                <li class="column-group-item">
                  <mat-checkbox
                    color="primary"
                    [checked]="isActionsColumn(column) | async"
                    [disabled]="(column.pinned || 0) > 0"
                    (change)="setColumnVisibility(column.id, $event.checked)"
                  >
                    {{ column.title }}
                  </mat-checkbox>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- Draggable List -->
<ng-template #filtered_dragable_list>
  <div class="section-container">
    <div class="section-header">Arrange columns</div>

    <div class="section-content">
      <!-- Pinned to top -->
      <ng-container *ngFor="let column of pinnedLeftColumns$ | async">
        <div *ngIf="column?.id !== 'select'" class="draggable-row draggable-row--pinned">
          <div class="drag-icon">
            <mat-icon>lock</mat-icon>
          </div>
          <div class="name">{{ column.name }}</div>
        </div>
      </ng-container>

      <!-- Draggable Items -->
      <div cdkDropList>
        <div
          *ngFor="let columnInfo of selectedColumns$ | async; let i = index"
          cdkDrag
          cdkDragLockAxis="y"
          [cdkDragData]="{ columnIndex: i, columnId: columnInfo.id }"
          (cdkDragDropped)="columnDroppedInList($event)"
          class="draggable-row"
        >
          <div class="drag-icon draggable" cdkDragHandle>
            <mat-icon>drag_handle</mat-icon>
          </div>

          <div class="name draggable" cdkDragHandle *ngIf="!isMobileScreen()">
            {{ columnInfo.name }}
          </div>
          <div class="name" *ngIf="isMobileScreen()">
            {{ columnInfo.name }}
          </div>
          <div class="remove-icon">
            <button mat-icon-button (click)="setColumnVisibility(columnInfo.id, false)">
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <div class="drag-placeholder" *cdkDragPlaceholder></div>
        </div>
      </div>

      <!-- Pinned to Bottom -->
      <ng-container *ngFor="let column of pinnedRightColumns$ | async">
        <div *ngIf="column?.id !== 'select'" class="draggable-row draggable-row--pinned">
          <div class="drag-icon">
            <mat-icon>lock</mat-icon>
          </div>
          <div class="name">{{ column.name }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
