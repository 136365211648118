// *********************************
// Code generated by sdkgen
// DO NOT EDIT!.
//
// Objects.
// *********************************
import * as i from '../interfaces/index';

export class Enum implements i.EnumInterface {
  name: string;
  description: string;
  fullyQualifiedName: string;

  static fromProto(proto: any): Enum {
    let m = new Enum();
    m = Object.assign(m, proto);
    return m;
  }

  constructor(kwargs?: i.EnumInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.name === 'undefined' &&
      typeof this.description === 'undefined' &&
      typeof this.fullyQualifiedName === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.name !== 'undefined') {
      toReturn['name'] = this.name;
    }
    if (typeof this.description !== 'undefined') {
      toReturn['description'] = this.description;
    }
    if (typeof this.fullyQualifiedName !== 'undefined') {
      toReturn['fullyQualifiedName'] = this.fullyQualifiedName;
    }
    return toReturn;
  }
}

export class ListEventsResponseEvent implements i.ListEventsResponseEventInterface {
  name: string;
  id: string;
  description: string;
  jsonSchema: string;
  jsonExample: string;

  static fromProto(proto: any): ListEventsResponseEvent {
    let m = new ListEventsResponseEvent();
    m = Object.assign(m, proto);
    return m;
  }

  constructor(kwargs?: i.ListEventsResponseEventInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.name === 'undefined' &&
      typeof this.id === 'undefined' &&
      typeof this.description === 'undefined' &&
      typeof this.jsonSchema === 'undefined' &&
      typeof this.jsonExample === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.name !== 'undefined') {
      toReturn['name'] = this.name;
    }
    if (typeof this.id !== 'undefined') {
      toReturn['id'] = this.id;
    }
    if (typeof this.description !== 'undefined') {
      toReturn['description'] = this.description;
    }
    if (typeof this.jsonSchema !== 'undefined') {
      toReturn['jsonSchema'] = this.jsonSchema;
    }
    if (typeof this.jsonExample !== 'undefined') {
      toReturn['jsonExample'] = this.jsonExample;
    }
    return toReturn;
  }
}

export class Field implements i.FieldInterface {
  name: string;
  description: string;
  fullyQualifiedName: string;

  static fromProto(proto: any): Field {
    let m = new Field();
    m = Object.assign(m, proto);
    return m;
  }

  constructor(kwargs?: i.FieldInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.name === 'undefined' &&
      typeof this.description === 'undefined' &&
      typeof this.fullyQualifiedName === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.name !== 'undefined') {
      toReturn['name'] = this.name;
    }
    if (typeof this.description !== 'undefined') {
      toReturn['description'] = this.description;
    }
    if (typeof this.fullyQualifiedName !== 'undefined') {
      toReturn['fullyQualifiedName'] = this.fullyQualifiedName;
    }
    return toReturn;
  }
}

export class GetSymbolResponse implements i.GetSymbolResponseInterface {
  service: Service;
  message: Message;
  enum: Enum;

  static fromProto(proto: any): GetSymbolResponse {
    let m = new GetSymbolResponse();
    m = Object.assign(m, proto);
    if (proto.service) {
      m.service = Service.fromProto(proto.service);
    }
    if (proto.message) {
      m.message = Message.fromProto(proto.message);
    }
    if (proto.enum) {
      m.enum = Enum.fromProto(proto.enum);
    }
    return m;
  }

  constructor(kwargs?: i.GetSymbolResponseInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.service === 'undefined' &&
      typeof this.message === 'undefined' &&
      typeof this.enum === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.service !== 'undefined' && this.service !== null) {
      toReturn['service'] = 'toApiJson' in this.service ? (this.service as any).toApiJson() : this.service;
    }
    if (typeof this.message !== 'undefined' && this.message !== null) {
      toReturn['message'] = 'toApiJson' in this.message ? (this.message as any).toApiJson() : this.message;
    }
    if (typeof this.enum !== 'undefined' && this.enum !== null) {
      toReturn['enum'] = 'toApiJson' in this.enum ? (this.enum as any).toApiJson() : this.enum;
    }
    return toReturn;
  }
}

export class ListEventsRequest implements i.ListEventsRequestInterface {
  cursor: string;
  pageSize: number;

  static fromProto(proto: any): ListEventsRequest {
    let m = new ListEventsRequest();
    m = Object.assign(m, proto);
    if (proto.pageSize) {
      m.pageSize = parseInt(proto.pageSize, 10);
    }
    return m;
  }

  constructor(kwargs?: i.ListEventsRequestInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.cursor === 'undefined' && typeof this.pageSize === 'undefined') {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.cursor !== 'undefined') {
      toReturn['cursor'] = this.cursor;
    }
    if (typeof this.pageSize !== 'undefined') {
      toReturn['pageSize'] = this.pageSize;
    }
    return toReturn;
  }
}

export class ListEventsResponse implements i.ListEventsResponseInterface {
  events: ListEventsResponseEvent[];
  nextCursor: string;
  hasMore: boolean;

  static fromProto(proto: any): ListEventsResponse {
    let m = new ListEventsResponse();
    m = Object.assign(m, proto);
    if (proto.events) {
      m.events = proto.events.map(ListEventsResponseEvent.fromProto);
    }
    return m;
  }

  constructor(kwargs?: i.ListEventsResponseInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.events === 'undefined' &&
      typeof this.nextCursor === 'undefined' &&
      typeof this.hasMore === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.events !== 'undefined' && this.events !== null) {
      toReturn['events'] = 'toApiJson' in this.events ? (this.events as any).toApiJson() : this.events;
    }
    if (typeof this.nextCursor !== 'undefined') {
      toReturn['nextCursor'] = this.nextCursor;
    }
    if (typeof this.hasMore !== 'undefined') {
      toReturn['hasMore'] = this.hasMore;
    }
    return toReturn;
  }
}

export class ListMicroservicesRequest implements i.ListMicroservicesRequestInterface {
  static fromProto(proto: any): ListMicroservicesRequest {
    let m = new ListMicroservicesRequest();
    m = Object.assign(m, proto);
    return m;
  }

  constructor(kwargs?: i.ListMicroservicesRequestInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    const toReturn: {
      [key: string]: any;
    } = {};

    return toReturn;
  }
}

export class ListMicroservicesResponse implements i.ListMicroservicesResponseInterface {
  microservices: Microservice[];

  static fromProto(proto: any): ListMicroservicesResponse {
    let m = new ListMicroservicesResponse();
    m = Object.assign(m, proto);
    if (proto.microservices) {
      m.microservices = proto.microservices.map(Microservice.fromProto);
    }
    return m;
  }

  constructor(kwargs?: i.ListMicroservicesResponseInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.microservices === 'undefined') {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.microservices !== 'undefined' && this.microservices !== null) {
      toReturn['microservices'] =
        'toApiJson' in this.microservices ? (this.microservices as any).toApiJson() : this.microservices;
    }
    return toReturn;
  }
}

export class ListSymbolsRequest implements i.ListSymbolsRequestInterface {
  microserviceId: string;
  version: string;

  static fromProto(proto: any): ListSymbolsRequest {
    let m = new ListSymbolsRequest();
    m = Object.assign(m, proto);
    return m;
  }

  constructor(kwargs?: i.ListSymbolsRequestInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.microserviceId === 'undefined' && typeof this.version === 'undefined') {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.microserviceId !== 'undefined') {
      toReturn['microserviceId'] = this.microserviceId;
    }
    if (typeof this.version !== 'undefined') {
      toReturn['version'] = this.version;
    }
    return toReturn;
  }
}

export class ListSymbolsResponse implements i.ListSymbolsResponseInterface {
  symbols: GetSymbolResponse[];

  static fromProto(proto: any): ListSymbolsResponse {
    let m = new ListSymbolsResponse();
    m = Object.assign(m, proto);
    if (proto.symbols) {
      m.symbols = proto.symbols.map(GetSymbolResponse.fromProto);
    }
    return m;
  }

  constructor(kwargs?: i.ListSymbolsResponseInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (typeof this.symbols === 'undefined') {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.symbols !== 'undefined' && this.symbols !== null) {
      toReturn['symbols'] = 'toApiJson' in this.symbols ? (this.symbols as any).toApiJson() : this.symbols;
    }
    return toReturn;
  }
}

export class Message implements i.MessageInterface {
  name: string;
  description: string;
  fullyQualifiedName: string;
  fields: Field[];
  jsonSchema: string;
  jsonExample: string;

  static fromProto(proto: any): Message {
    let m = new Message();
    m = Object.assign(m, proto);
    if (proto.fields) {
      m.fields = proto.fields.map(Field.fromProto);
    }
    return m;
  }

  constructor(kwargs?: i.MessageInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.name === 'undefined' &&
      typeof this.description === 'undefined' &&
      typeof this.fullyQualifiedName === 'undefined' &&
      typeof this.fields === 'undefined' &&
      typeof this.jsonSchema === 'undefined' &&
      typeof this.jsonExample === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.name !== 'undefined') {
      toReturn['name'] = this.name;
    }
    if (typeof this.description !== 'undefined') {
      toReturn['description'] = this.description;
    }
    if (typeof this.fullyQualifiedName !== 'undefined') {
      toReturn['fullyQualifiedName'] = this.fullyQualifiedName;
    }
    if (typeof this.fields !== 'undefined' && this.fields !== null) {
      toReturn['fields'] = 'toApiJson' in this.fields ? (this.fields as any).toApiJson() : this.fields;
    }
    if (typeof this.jsonSchema !== 'undefined') {
      toReturn['jsonSchema'] = this.jsonSchema;
    }
    if (typeof this.jsonExample !== 'undefined') {
      toReturn['jsonExample'] = this.jsonExample;
    }
    return toReturn;
  }
}

export class Microservice implements i.MicroserviceInterface {
  id: string;
  name: string;
  description: string;
  versions: string[];

  static fromProto(proto: any): Microservice {
    let m = new Microservice();
    m = Object.assign(m, proto);
    return m;
  }

  constructor(kwargs?: i.MicroserviceInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.id === 'undefined' &&
      typeof this.name === 'undefined' &&
      typeof this.description === 'undefined' &&
      typeof this.versions === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.id !== 'undefined') {
      toReturn['id'] = this.id;
    }
    if (typeof this.name !== 'undefined') {
      toReturn['name'] = this.name;
    }
    if (typeof this.description !== 'undefined') {
      toReturn['description'] = this.description;
    }
    if (typeof this.versions !== 'undefined') {
      toReturn['versions'] = this.versions;
    }
    return toReturn;
  }
}

export class RPC implements i.RPCInterface {
  name: string;
  description: string;
  fullyQualifiedName: string;

  static fromProto(proto: any): RPC {
    let m = new RPC();
    m = Object.assign(m, proto);
    return m;
  }

  constructor(kwargs?: i.RPCInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.name === 'undefined' &&
      typeof this.description === 'undefined' &&
      typeof this.fullyQualifiedName === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.name !== 'undefined') {
      toReturn['name'] = this.name;
    }
    if (typeof this.description !== 'undefined') {
      toReturn['description'] = this.description;
    }
    if (typeof this.fullyQualifiedName !== 'undefined') {
      toReturn['fullyQualifiedName'] = this.fullyQualifiedName;
    }
    return toReturn;
  }
}

export class Service implements i.ServiceInterface {
  name: string;
  description: string;
  fullyQualifiedName: string;
  rpcs: RPC[];

  static fromProto(proto: any): Service {
    let m = new Service();
    m = Object.assign(m, proto);
    if (proto.rpcs) {
      m.rpcs = proto.rpcs.map(RPC.fromProto);
    }
    return m;
  }

  constructor(kwargs?: i.ServiceInterface) {
    if (!kwargs) {
      return;
    }
    Object.assign(this, kwargs);
  }

  toApiJson(): object {
    if (
      typeof this.name === 'undefined' &&
      typeof this.description === 'undefined' &&
      typeof this.fullyQualifiedName === 'undefined' &&
      typeof this.rpcs === 'undefined'
    ) {
      return {};
    }

    const toReturn: {
      [key: string]: any;
    } = {};

    if (typeof this.name !== 'undefined') {
      toReturn['name'] = this.name;
    }
    if (typeof this.description !== 'undefined') {
      toReturn['description'] = this.description;
    }
    if (typeof this.fullyQualifiedName !== 'undefined') {
      toReturn['fullyQualifiedName'] = this.fullyQualifiedName;
    }
    if (typeof this.rpcs !== 'undefined' && this.rpcs !== null) {
      toReturn['rpcs'] = 'toApiJson' in this.rpcs ? (this.rpcs as any).toApiJson() : this.rpcs;
    }
    return toReturn;
  }
}
