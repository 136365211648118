<app-primary-navigation [secondaryNavigation]="sidenav"></app-primary-navigation>
<mat-sidenav-container class="container">
  <mat-sidenav
    #sidenav
    [opened]="!mobileQuery.matches"
    class="nav"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
  >
    <mat-nav-list>
      <div *ngFor="let navItem of NavItems">
        <a mat-list-item href="{{ navItem.url }}" target="_blank" *ngIf="navItem.url.startsWith('http')">
          {{ navItem.label }}&nbsp;
          <mat-icon>open_in_new</mat-icon>
        </a>
        <a mat-list-item [routerLink]="[navItem.url]" routerLinkActive="active" *ngIf="!navItem.url.startsWith('http')">
          {{ navItem.label }}
        </a>
        <div *ngFor="let subNavItem of navItem.children">
          <a
            mat-list-item
            href="{{ subNavItem.url }}"
            target="_blank"
            class="indent"
            *ngIf="subNavItem.url.startsWith('http')"
          >
            {{ subNavItem.label }}&nbsp;
            <mat-icon>open_in_new</mat-icon>
          </a>
          <a
            mat-list-item
            [routerLink]="[subNavItem.url]"
            routerLinkActive="active"
            class="indent"
            *ngIf="!subNavItem.url.startsWith('http')"
          >
            {{ subNavItem.label }}
          </a>
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content mat-typography" #content (cdkObserveContent)="toc.onContentChange()">
    <router-outlet></router-outlet>
    <div class="toc-placeholder" class="hide-lt-lg"></div>
    <app-toc #toc [content]="content" class="hide-lt-lg"></app-toc>
  </mat-sidenav-content>
</mat-sidenav-container>
