<div class="flex-container">
  <div class="flex-access-token-header" cdkDragHandle cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="small-header-inside-flex-header">Access Token Generator</div>
    <div class="small-detail-inside-flex-header">
      Generate token to access API’s. You can read about it
      <button (click)="redirectToDocs(redirectionSlug)"><div class="nav-link-in-detail-box">here</div></button>
    </div>
  </div>
  <div class="flex-access-token-body">
    <form class="access-token-form" [formGroup]="tokenForm" (ngSubmit)="onAtokenSubmit2()" novalidate>
      <div mat-card-content>
        <glxy-form-field [size]="'small'" [prefixIcon]="'search'" bottomSpacing="none">
          <glxy-label>Scopes</glxy-label>
          <input
            type="text"
            placeholder="New Scope..."
            #scopeInput
            matInput
            [matAutocomplete]="auto"
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [formControl]="tokenForm.controls.myControl"
            (matChipInputTokenEnd)="addKeywordFromInput($event)"
          />
          <small
            [class.block-none]="tokenForm.controls.scope.untouched || tokenForm.controls.scope.value?.length > 0"
            class="invalid-form-data"
          >
            *scope is mandatory
          </small>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let scope of filteredOptions | async" [value]="scope">
              {{ scope }}
            </mat-option>
          </mat-autocomplete>
        </glxy-form-field>
        <mat-chip-grid size="small" #chipGrid aria-label="Scope selection" [formControl]="tokenForm.controls['scope']">
          <mat-chip-row *ngFor="let scope of tokenForm.controls.scope.value" (removed)="remove(scope)">
            {{ scope }}
            <button matChipRemove [attr.aria-label]="'remove ' + scope">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <glxy-form-field [size]="'small'" [showInputDecoration]="true" bottomSpacing="none">
          <glxy-label>Environment</glxy-label>
          <mat-radio-group required formControlName="environment">
            <mat-radio-button color="accent" value="demo">Demo</mat-radio-button>
            <mat-radio-button color="accent" value="prod">Prod</mat-radio-button>
          </mat-radio-group>
        </glxy-form-field>
        <glxy-form-field bottomSpacing="default" size="small">
          <glxy-label>Partner Id</glxy-label>
          <input matInput required id="partner" name="partner" formControlName="partner" />
        </glxy-form-field>

        <div mat-dialog-actions>
          <button mat-flat-button color="accent" type="submit" [disabled]="tokenForm.invalid">Generate</button>
        </div>
      </div>
    </form>
  </div>
  <div>
    <div class="access-token-table" *ngIf="tokens.length > 0">
      <app-access-token-dialog-footer [tokens]="tokens"></app-access-token-dialog-footer>
    </div>
  </div>
</div>
