<ng-container *ngIf="row; else mainCheckbox">
  <mat-checkbox
    (change)="onSelectChange($event)"
    [checked]="selectionService.isSelected(row) || disabledRow"
    [disabled]="disabledRow"
  ></mat-checkbox>
</ng-container>

<ng-template #mainCheckbox>
  <mat-checkbox
    (change)="onSelectChange($event)"
    [checked]="(selectionService.hasSelection$ | async) && (selectionService.allSelected$ | async)"
    [indeterminate]="(selectionService.hasSelection$ | async) && (selectionService.allSelected$ | async) === false"
  ></mat-checkbox>
</ng-template>
