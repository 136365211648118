import { AfterViewInit, Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GalaxyDataSource } from '../../datasource/datasource';
import { GalaxyPaginatorText } from '../../datasource/paginator-text';

export function useFactory(translateService: TranslateService): GalaxyPaginatorText {
  return new GalaxyPaginatorText(translateService);
}

const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 15];
const DEFAULT_PAGE_SIZE = 20;

interface CurrentCursor {
  pi: number;
  ps: number;
  c: Record<number, string>;
}

@Component({
  selector: 'glxy-table-content-footer',
  templateUrl: './table-content-footer.component.html',
  styleUrls: ['./table-content-footer.component.scss'],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory,
      deps: [TranslateService],
    },
  ],
})
export class TableContentFooterComponent implements AfterViewInit, OnInit, OnDestroy {
  @HostBinding('class') class = 'glxy-table-content-footer';

  @Input({ required: true }) dataSource!: GalaxyDataSource<any>;
  @Input({ required: true, transform: (v: number[] | undefined) => v ?? DEFAULT_PAGE_SIZE_OPTIONS })
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;

  @Input() id = '';

  _pageSize = DEFAULT_PAGE_SIZE;
  @Input() set pageSize(pageSize: number) {
    if (this.id) {
      const savedPageSize = localStorage.getItem(`table-${this.id}-pageSize`);
      if (savedPageSize) {
        this._pageSize = parseInt(savedPageSize, 10);
        return;
      }
    }
    this._pageSize = pageSize;
  }

  @ViewChild(MatPaginator) paginator?: MatPaginator;

  private readonly subscriptions: Subscription[] = [];

  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    if (this.id) {
      // Only read and write cursor to query params if we have an id
      const cursorParam = this.activatedRoute.snapshot.queryParams.cursor;
      if (cursorParam) {
        const cursor = JSON.parse(atob(cursorParam)) as CurrentCursor;
        this.dataSource.loadCursors(cursor.c);
        this.dataSource.state = {
          pageSize: cursor.ps,
          pageIndex: cursor.pi,
          shouldRefetch: true,
        };
        this._pageSize = cursor.ps;
      }

      this.subscriptions.push(
        combineLatest([this.activatedRoute.queryParams, this.dataSource.pageIndex$])
          .pipe(
            tap(([queryParams, pageIndex]) => {
              const newQueryParams = { ...queryParams };
              if (pageIndex) {
                const cursorParam = btoa(
                  JSON.stringify({
                    pi: pageIndex,
                    ps: this.dataSource?.state.pageSize,
                    c: this.dataSource?.getCursors(),
                  } as CurrentCursor),
                );
                newQueryParams.cursor = cursorParam;
              } else {
                delete newQueryParams['cursor'];
              }
              // Note: `queryParamsHandling: 'merge'` doesn't work when multiple components are adjusting the query params
              // at the same time. Without this other components may not be able to write to the query params.
              if (queryParams.cursor === newQueryParams.cursor) {
                return;
              }
              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: newQueryParams,
              });
            }),
          )
          .subscribe(),
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.state = { pageSize: this.paginator.pageSize, shouldRefetch: true };
    }
  }

  pageChanged(event: PageEvent): void {
    if (this.id) {
      localStorage.setItem(`table-${this.id}-pageSize`, event.pageSize.toString());
    }
    let pageIndex = event.pageIndex;
    const cursor = this.dataSource.getCursor(pageIndex, event.pageSize);
    if (!cursor && pageIndex > 0) {
      pageIndex = 0;
    }
    this.dataSource.state = { pageSize: event.pageSize, pageIndex: pageIndex, shouldRefetch: true, loading: true };
  }
}
