import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { AccessTokenService, getAccessTokens, validateToken } from '../../access-token.service';
import { map, Observable, startWith } from 'rxjs';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { MatChipInputEvent } from '@angular/material/chips';

export interface IDialogResult {
  url: string;
}
// Factory produces a storage class for the playground
export function storageFactory(): OAuthStorage {
  class PlaygroundLocalStorage implements Storage {
    public store: Storage = window.localStorage;
    private keyPrefix: string;

    constructor(keyPrefix: string) {
      this.keyPrefix = keyPrefix;
    }

    [name: string]: any;

    get length(): number {
      return this.store.length;
    }

    clear(): void {
      this.store.clear();
    }

    getItem(key: string): string | null {
      return this.store.getItem(this.keyPrefix + key);
    }

    key(index: number): string | null {
      return this.store.key(index);
    }

    removeItem(key: string): void {
      this.store.removeItem(this.keyPrefix + key);
    }

    setItem(key: string, value: string): void {
      this.store.setItem(this.keyPrefix + key, value);
    }
  }
  return new PlaygroundLocalStorage('developer-center-' + '-');
}

export interface IAccessToken {
  environment: string;
  partner: string;
  scopes: string;
  accessToken: string;
}

export interface IFormData {
  scope: string;
  environment: string;
  partner: string;
  //urlForRedirection: string;
}
@Component({
  selector: 'app-galaxy-access-token',
  templateUrl: './access-token.component.html',
  styleUrls: ['./access-token.component.scss'],
  providers: [OAuthService, { provide: OAuthStorage, useFactory: storageFactory }],
})
export class AccessTokenComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  redirectionSlug = '/platform';
  scopes = [
    'address',
    'advertising',
    'automation:read',
    'business',
    'business:read',
    'customers',
    'email',
    'financial',
    'listing',
    'openid',
    'order',
    'order:read',
    'partner:read',
    'phone',
    'product',
    'profile',
    'reputation',
    'reviews',
    'sales.account',
    'sales.contact',
    'sales.proposals',
    'self.user.admin',
    'self.user.contact',
    'self.user.contact:read',
    'social',
    'user:read',
    'user.admin',
    'user.contact',
    'user.contact:read',
    'user.list',
    'user.permission',
    'user.permission:read',
    'user.profile',
    'user.profile:read',
  ];
  selectedScopes: string[] = [];
  accessToken$: Observable<string>;
  filteredOptions: Observable<string[]>;
  tokenForm = new FormGroup({
    scope: new FormControl([], Validators.required),
    environment: new FormControl('demo', Validators.required),
    partner: new FormControl('', Validators.required),
    myControl: new FormControl(''), //This is to support the autocomplete
  });
  tokens: IAccessToken[] = [];
  latestTokens: IAccessToken[] = [];
  @ViewChild('scopeInput') scopeInput: ElementRef<HTMLInputElement>;
  constructor(
    public dialogRef: MatDialogRef<AccessTokenComponent>,
    private accessTokenService: AccessTokenService,
    private snackbarService: SnackbarService,
  ) {
    this.tokens = getAccessTokens();
    this.latestTokens = getAccessTokens();
  }

  ngOnInit(): void {
    this.filteredOptions = this.getFilterOptions();
  }

  private getFilterOptions(): Observable<string[]> {
    return this.tokenForm.controls.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this._filter(value);
      }),
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.scopes.filter((option) => option.toLowerCase().includes(filterValue));
  }

  get scope(): FormControl<any[]> {
    return this.tokenForm.controls.scope;
  }

  remove(scope: string): void {
    const sel = this.scope.value;
    const i = sel.indexOf(scope);
    if (i >= 0) {
      sel.splice(i, 1);
      this.tokenForm.controls.scope.setValue(sel);
      this.scopes.push(scope);
      this.filteredOptions = this.getFilterOptions();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.pushToScopeList(event.option.value);
  }

  pushToScopeList(scope: string) {
    const sel = this.scope.value;
    sel.push(scope);
    const op = this.scopes.indexOf(scope);
    this.scopes.splice(op, 1);
    this.filteredOptions = this.getFilterOptions();
    this.scopeInput.nativeElement.value = '';
  }

  redirectToDocs(url: string): void {
    this.dialogRef.close({ url: url } as IDialogResult);
  }

  //A token is generated irrespective of the existence of a token
  onAtokenSubmit2() {
    const obj = {
      scope: this.tokenForm.controls.scope.value.join(' '),
      environment: this.tokenForm.controls.environment.value,
      partner: this.tokenForm.controls.partner.value,
      urlForRedirection: window.location.href,
    };
    const existingToken = validateToken(obj);
    if (Object.keys(existingToken).length === 0) {
      // Open a new window to load the discovery document
      this.accessTokenService.loginAndSetupTokenOnDevCenter(obj).then((token) => {
        if (Object.keys(token).length > 0) {
          this.addElement(token);
          this.snackbarService.openSuccessSnack('Generated access token', {
            duration: 3000,
          });
          window.setInterval(() => {
            window.location.reload();
          }, 3000);
        } else {
          this.snackbarService.openErrorSnack('Access token generation failed', {
            duration: 3000,
          });
        }
      });
    } else {
      this.snackbarService.openErrorSnack(
        'Token already generated. Use existing token or refresh it if it has expired.',
        {
          action: 'Refresh token',
          duration: 10000,
          actionCallback: () => {
            this.accessTokenService.refreshToken(obj);
          },
        },
      );
    }
  }

  public addElement(element) {
    this.tokens.push(element);
    this.tokens.splice(0, this.tokens.length - 5);
    window.localStorage.setItem('vendasta_tokens', JSON.stringify(this.tokens));
    this.latestTokens = this.tokens;
    //Closing dialog. Because mat-table renderRows is not working in galaxy implementation
    this.dialogRef.close();
  }

  //Add scope on enter key press
  addKeywordFromInput($event: MatChipInputEvent) {
    if ($event.value) {
      this.pushToScopeList($event.value);
    }
  }
}
