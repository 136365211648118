import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GalaxyDataSource, Row } from '@vendasta/galaxy/table';
import { GalaxyColumnDef } from '@vendasta/galaxy/table/src/table.interface';
import { Observable } from 'rxjs';
import { AccessTokenDataService } from '../../access-token-service.service';
import { AccessTokenService, validateAndSetBearerToken, validateToken } from '../../access-token.service';
import { AccessTokenComponent, IAccessToken, IFormData } from '../access-token/access-token.component';

interface IStoplightToken {
  OAuth2Prod: string;
  OAuth2Demo: string;
}

@Component({
  selector: 'app-access-token-dialog-footer',
  templateUrl: './access-token-dialog-footer.component.html',
  styleUrls: ['./access-token-dialog-footer.component.scss'],
  providers: [AccessTokenDataService],
})
export class AccessTokenDialogFooterComponent {
  dataSource: GalaxyDataSource<Row>;
  columns$: Observable<GalaxyColumnDef[]>;
  @Input() tokens: IAccessToken[];
  constructor(
    private readonly tableComprehensiveService: AccessTokenDataService,
    private clipboard: Clipboard,
    private accessTokenService: AccessTokenService,
    public dialogRef: MatDialogRef<AccessTokenComponent>,
  ) {
    this.dataSource = this.tableComprehensiveService.getDataSource();
    this.columns$ = this.tableComprehensiveService.getColumn$();
  }

  get getStoplightToken(): IStoplightToken {
    let token = window.localStorage.getItem('TryIt_securitySchemeValues');
    token = JSON.parse(token);
    return {
      OAuth2Prod: token && token['OAuth2Prod'] ? token['OAuth2Prod'].split(' ')[1] : '',
      OAuth2Demo: token && token['OAuth2Demo'] ? token['OAuth2Demo'].split(' ')[1] : '',
    };
  }

  copy(rowId: Row): void {
    this.clipboard.copy('Bearer ' + String(rowId['token']));
  }

  refresh(rowId: Row): void {
    const obj: IFormData = {
      scope: [rowId.data['scopes']['value']].join(' '),
      environment: rowId['env'],
      partner: rowId['partner'],
    };
    const existingAccessToken = validateToken(obj);
    if (Object.keys(existingAccessToken).length > 0) {
      this.accessTokenService.refreshToken(obj);
      //Force closing the dialog because the table refresh was not happening
      this.dialogRef.close();
    }
  }
  setToken(rowId: Row): void {
    validateAndSetBearerToken(rowId['token'], rowId['env']);
    window.location.reload();
  }

  alertHelloWorld(): void {
    alert('Hello World!');
  }

  protected formatTime(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }

  formatString() {
    return '<del>exp</del>';
  }
}
