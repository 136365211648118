import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

import { GalaxyCheckboxConfigInterface } from './interface';

@Component({
  selector: 'glxy-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @HostBinding('class') class = 'glxy-checkbox';

  /** Id of the checkbox */
  @Input() id?: string;
  /** The form control for the input. If no form control is passed in, it will create its own */
  @Input() formControl?: UntypedFormControl;
  /** The label for the checkbox. If one is not provided, it will use the value passed in via ng-content */
  @Input() label?: string;
  /** Sets the disabled state of the checkboc */
  @Input() set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }
  @Input() config?: GalaxyCheckboxConfigInterface;

  inputValue = false;
  isDisabled = false;

  // Disables eslint on next line since the onChange function is meant to be overridden
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_value: boolean): void => {
    // This should be implemented to correctly implement ControlValueAccessor
  };

  // Disables eslint on next line since the onTouched function is meant to be overridden
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTouched = (_value: boolean): void => {
    // This should be implemented to correctly implement ControlValueAccessor
  };

  ngOnInit(): void {
    this.setupControl();
  }

  setupControl(): void {
    if (this.config) {
      this.formControl = this.config?.formControl || this.formControl;
      this.label = this.config?.label || this.label;
      this.disabled = this.config?.disabled || this.isDisabled;
    }
    if (!this.formControl) {
      this.formControl = new UntypedFormControl(this.inputValue);
    }
    this.setDisabledState(this.isDisabled);
  }

  writeValue(value: boolean): void {
    this.inputValue = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: boolean) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Do nothing when no change
    if (isDisabled === this.formControl?.disabled) {
      return;
    }

    this.isDisabled = isDisabled;

    if (this.formControl) {
      if (isDisabled) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }
}
