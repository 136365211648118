<div class="glxy-table-header">
  <div class="glxy-table-filter-and-search">
    <div class="filter-search-group">
      <ng-container *ngIf="showFilters">
        <button
          *ngIf="showFiltersButton; else customFilterButton"
          mat-stroked-button
          class="table-btn glxy-filters-toggle"
          data-action="clicked-glxy-table-filters-toggle"
          [ngClass]="{ on: showFiltersOpen }"
          (click)="toggleFilterBar()"
        >
          <span class="glxy-filters-indicator-wrap table-btn-icon">
            <mat-icon>filter_list</mat-icon>
            <span
              *ngIf="showFiltersApplied"
              class="glxy-filters-indicator"
              [attr.aria-label]="'GALAXY.TABLE.FILTERS.APPLIED_INDICATOR' | translate"
            ></span>
          </span>
          <span class="hide-on-small">{{ 'GALAXY.TABLE.FILTERS.TOGGLE_BUTTON' | translate }}</span>
        </button>
      </ng-container>
      <glxy-form-field
        *ngIf="showSearch"
        class="glxy-table-search"
        suffixIcon="search"
        [showLabel]="false"
        [bottomSpacing]="false"
      >
        <glxy-label>Search</glxy-label>
        <input type="search" placeholder="Search" matInput [formControl]="searchControl" />
      </glxy-form-field>
    </div>
    <div class="after-search">
      <ng-content select="[after-search]"></ng-content>
      @if (showDisplayOption) {
        <mat-button-toggle-group
          class="display-options-btn"
          [value]="this.selectedDisplayOption"
          (change)="onChangeDisplayOption($event.value)"
        >
          <mat-button-toggle value="grid" data-action="clicked-glxy-table-display-options">
            <mat-icon>grid_view</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="list" data-action="clicked-glxy-table-display-options">
            <mat-icon>format_list_bulleted</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      }
    </div>
  </div>

  <div class="glxy-table-actions">
    <button *ngIf="showSort" mat-stroked-button class="table-btn" data-action="clicked-glxy-table-sort">
      <span class="table-btn-icon"><mat-icon>sort</mat-icon></span>
      <span class="hide-on-small">Sort</span>
    </button>
    <glxy-column-sort *ngIf="showColumnArrange" [type]="columnArrangeType"></glxy-column-sort>
    <button
      *ngIf="showExport"
      mat-stroked-button
      class="table-btn"
      data-action="clicked-glxy-table-export"
      (click)="this.export.emit()"
    >
      <span class="table-btn-icon"><mat-icon>cloud_download</mat-icon></span>
      <span class="hide-on-small">Export</span>
    </button>
  </div>
</div>
<div class="glxy-filter-bar" *ngIf="showFilters" [@openClose]="showFiltersOpen ? 'open' : 'closed'">
  <div class="glxy-filter-bar-inner-space">
    <ng-content select="[filters-area]"></ng-content>
  </div>
</div>
<div class="glxy-table-selection-bar" *ngIf="showActions && (numSelected$ | async) as numSelected">
  <div *ngIf="!showSelectedAllCount" class="selection-count">{{ numSelected }} selected &rarr;</div>
  <div class="optional-select-all">
    <ng-content select="[optional-select-all]"></ng-content>
  </div>
  <button
    mat-stroked-button
    class="table-btn"
    [matMenuTriggerFor]="actionsMenu"
    data-action="clicked-glxy-table-actions"
  >
    <span class="table-btn-icon"><mat-icon>flash_on</mat-icon></span>
    <span>Actions</span>
  </button>
  <mat-menu #actionsMenu="matMenu">
    <ng-content select="[mat-menu-item]"></ng-content>
  </mat-menu>
</div>

<ng-template #customFilterButton><ng-content select="[filter-button]"></ng-content></ng-template>
