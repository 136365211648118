import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HostService } from '../developer_center_sdk/src/lib/_generated';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
})
export class MarkdownComponent {
  public md$: Observable<string>;

  constructor(private route: ActivatedRoute, hostService: HostService, private httpClient: HttpClient) {
    const host = hostService.hostWithScheme();

    this.md$ = this.route.data.pipe(
      switchMap((data) => {
        let src = null;
        if (data.asset) {
          // Convenience for local development, but currently won't work if you check it in this way.
          src = `/developers/assets/${data.asset}`;
        } else {
          src = `${host}/doc?path=${data.path}&repo=${data.repo}`;
        }
        const headers = new HttpHeaders().set('Content-Type', 'text/plain');
        return this.httpClient.get(src, { headers: headers, responseType: 'text' });
      }),
    );
  }
}
