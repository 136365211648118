import { Directive, Input } from '@angular/core';

export const GRID_MIN_CARD_WIDTH = 200;

@Directive({
  selector: '[glxyGridMinCardWidth]',
})
export class GalaxyGridMinCardWidthDirective {
  @Input({ transform: (value: string): number => parseInt(value) }) glxyGridMinCardWidth = GRID_MIN_CARD_WIDTH;
}
