import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'glxy-blank-value',
  template: ` <div class="symbol">—</div> `,
  styleUrls: ['./blank-value.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class BlankValueComponent {}

@Component({
  selector: 'glxy-blank-value-renderer',
  template: ` <glxy-blank-value></glxy-blank-value> `,
  imports: [CommonModule, BlankValueComponent],
  standalone: true,
})
export class BlankValueRendererComponent {}
