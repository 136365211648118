import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isLocalStorageAvailable } from './util';

declare const iamSession: string;
const LOCAL_SESSION_KEY = 'local_session';
const USE_LOCAL_STORAGE = isLocalStorageAvailable();

@Injectable({ providedIn: 'root' })
export class SessionService {
  private sessionIdSubject$$: BehaviorSubject<string | null>;

  constructor() {
    if (typeof iamSession !== 'undefined' && !!iamSession) {
      this.sessionIdSubject$$ = new BehaviorSubject<string | null>(iamSession);
    } else if (USE_LOCAL_STORAGE) {
      const localSession = this.getLocalStoredSession();
      this.sessionIdSubject$$ = new BehaviorSubject(localSession);
    } else {
      this.sessionIdSubject$$ = new BehaviorSubject<string | null>(null);
    }

    this.sessionIdSubject$$.subscribe((sessionId) => {
      if (USE_LOCAL_STORAGE) {
        this.setLocalStoredSession(sessionId);
      }
    });
  }

  getSessionId(): Observable<string | null> {
    return this.sessionIdSubject$$.asObservable();
  }

  setSessionId(sessionId: string): void {
    this.sessionIdSubject$$.next(sessionId);
  }

  clearSessionId(): void {
    this.sessionIdSubject$$.next('');
  }

  private getLocalStoredSession(): string | null {
    return localStorage.getItem(LOCAL_SESSION_KEY);
  }

  private setLocalStoredSession(session: string | null): void {
    localStorage.setItem(LOCAL_SESSION_KEY, session ?? '');
  }
}
